import React, { useEffect, useState, useRef, useContext } from 'react';
import { IChatMessage } from 'models';
import { send } from 'process';
import Chat from './Chat';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import VideoCallContext from 'contexts/videocall.context';

interface IProps {
  height: number;
}
function ChatList({ height }: IProps) {
  const [message, setMessage] = useState('');
  const [messageList, setMessageList] = useState<IChatMessage[]>([]);
  const { localUser } = useContext(VideoCallContext);
  const { publisher } = localUser;
  const chatScrollRef = useRef<HTMLDivElement>(null);
  const videoRoom = useSelector((state: RootState) => state.videoRoom);

  useEffect(() => {
    if (publisher && publisher.stream) {
      publisher.stream.session.on('signal:chat', (event: any) => {
        const data = JSON.parse(event.data);
        const newMessage: IChatMessage = {
          connectionId: event.from.connectionId,
          userName: data.userName,
          message: data.message,
        };
        setMessageList((data) => [...data, newMessage]);
        scrollToBottom();
      });
    }
  }, [publisher]);

  const sendMessage = () => {
    if (localUser && message) {
      const chatMessage = message.replace(/ +(?= )/g, '');
      const data = {
        message: chatMessage,
        userName: localUser.userName,
        streamId: localUser.publisher.stream.streamId,
      };

      publisher.stream.session.signal({
        data: JSON.stringify(data),
        type: 'chat',
      });

      setMessage('');
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    sendMessage();
  };
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setMessage(event.currentTarget.value);
  };
  const scrollToBottom = () => {
    setTimeout(() => {
      try {
        if (chatScrollRef && chatScrollRef.current) {
          chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
        }
      } catch (error) {
        console.log(error);
      }
    }, 20);
  };

  return (
    <div id='chatContent' style={{ height }}>
      <div
        className={
          videoRoom.isZoomIn ? 'chattingBox expandExe' : 'chattingBox'
        }>
        <div className='chatOut' ref={chatScrollRef}>
          {messageList.map((chat, index) => {
            return (
              <Chat
                chat={chat}
                key={index}
                me={localUser.connectionId === chat.connectionId}
              />
            );
          })}
          <div className='message'>
            <div className='mBox'>
              <form onSubmit={handleSubmit}>
                <input
                  type='text'
                  placeholder='Text Message'
                  onChange={handleChange}
                  value={message}
                />
                <button onClick={send}>
                  <img src='/images/btn_message_send.png' alt='button' />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatList;
