import axios, { AxiosResponse } from 'axios';
import { EGGPLAY_API_SERVER, EGGSCHOOL_API_SERVER } from 'config/config';
import { ClassMode } from '../models';

axios.defaults.withCredentials = true;

const PREVIEW_CLASS = {
  classMode: 'PREVIEW',
  episodeId: '',
  stepName: '',
  className: 'Preview Class',
  bookName: 'Preview Episode',
  capacity: 1,
};

export const getClassInfo = async (
  service: string,
  uuid: string,
  mode: string,
  token?: string,
  episodeId?: number
): Promise<any> => {
  if (service === 'eggschool' && mode === 'preview') {
    return {
      userType: 'tutor',
      classInfo: { ...PREVIEW_CLASS, episodeId: episodeId },
    };
  } else if (service === 'eggplay' && mode === 'class') {
    const response = await axios.post(`${EGGPLAY_API_SERVER}/api/studyroom`, {
      uuid,
      token,
    });
    return response.data;
  } else if (service === 'eggplay' && mode === 'mock') {
    const response = await axios.post(
      `${EGGPLAY_API_SERVER}/api/studyroommock`,
      {
        uuid,
        token,
      }
    );
    return response.data;
  } else if (service === 'eggschool') {
    const response = await axios.get(
      `${EGGSCHOOL_API_SERVER}/api/studyroom?uuid=${uuid}`
    );
    return response.data;
  }
  return null;
};

export const sendSessionId = async (
  uuid: string,
  sessionId: string,
  classMode: ClassMode
): Promise<AxiosResponse> => {
  return axios.patch(`${EGGSCHOOL_API_SERVER}/api/studyroom`, {
    sessionId,
    uuid,
    classMode,
  });
};

export const getEpisodePage = async (
  episodeId: string
): Promise<AxiosResponse> => {
  return axios.get(
    `${EGGSCHOOL_API_SERVER}/api/books/episodes/page?episodeId=${episodeId}`
  );
};

export const sendClassComplete = async (
  uuid: string
): Promise<AxiosResponse> => {
  return axios.patch(`${EGGSCHOOL_API_SERVER}/api/studyroom/submit`, { uuid });
};

export const sendIssue = async (payload: any): Promise<AxiosResponse> => {
  return axios.post(`${EGGSCHOOL_API_SERVER}/api/studyroom/issue`, payload);
};

export const sendInOutLog = async (payload: any): Promise<AxiosResponse> => {
  return axios.post(`${EGGSCHOOL_API_SERVER}/api/studyroom/log`, payload);
};

export const pingStudy = async (payload: any): Promise<AxiosResponse> => {
  return axios.post(`${EGGSCHOOL_API_SERVER}/api/studyroom/monitor`, payload);
};

export const pingEggComon = async (payload: {
  uuid: string;
  token: string;
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${EGGPLAY_API_SERVER}/api/studyroom/monitor`,
    payload
  );
};
