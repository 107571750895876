export interface IClassInfo {
  uuid: string;
  service: string;
  classMode: 'CLASSROOM' | 'MOCK' | 'PREVIEW' | undefined;
  episodeId: number;
  capacity: number;
  className?: string;
  bookName?: string;
  stepName?: string;
  startDateTime?: string;
  endDateTime?: string;
  scheduleId?: number;
  isLevelTest?: boolean;
}

export const initialClassInfoValue: IClassInfo = {
  uuid: '',
  service: '',
  classMode: undefined,
  episodeId: 0,
  capacity: 0,
  className: '',
  bookName: '',
  stepName: '',
  startDateTime: '',
  endDateTime: '',
  scheduleId: 0,
  isLevelTest: false,
};

export interface IUser {
  userName: string;
  userType: 'tutor' | 'student' | undefined;
  userID: string;
  connectionId?: string;
  audioActive: boolean;
  videoActive: boolean;
  selectedVideoInput: string | undefined;
  selectedAudioInput: string | undefined;
  selectedAudioOutput: string | undefined;
  screenShareActive: boolean;
  publisher: any;
  type: 'local' | 'remote' | undefined;
}

export const initialUserValue: IUser = {
  userName: '',
  userType: undefined,
  userID: '',
  connectionId: '',
  audioActive: true,
  videoActive: true,
  selectedVideoInput: '',
  selectedAudioInput: '',
  selectedAudioOutput: '',
  screenShareActive: false,
  publisher: undefined,
  type: undefined,
};

export interface IVideoCall {
  server: number;
  token: string;
  localUser: IUser;
  session: any;
  classInfo: IClassInfo;
  // sessionName: string;
  remoteUsers: IUser[];
  recordingId: string | null;
  isRecorded: boolean;
  showSetting: boolean;
  setServer: (server: number) => void;
  setToken: (token: string) => void;
  setLocalUser: (localUser: IUser) => void;
  setSession: (session: any) => void;
  setClassInfo: (classInfo: IClassInfo) => void;
  // setSessionName: (sessionName: string) => void;
  setRemoteUsers: (remoteUsers: IUser[]) => void;
  startRecording: (sessionId: string) => Promise<boolean>;
  stopRecording: (recordingId: string) => Promise<boolean>;
  setShowSetting: (showSetting: boolean) => void;
}

export const initialVideoCallValue: IVideoCall = {
  server: 1,
  token: '',
  localUser: initialUserValue,
  session: undefined,
  classInfo: initialClassInfoValue,
  // sessionName: '',
  remoteUsers: [],
  recordingId: null,
  isRecorded: false,
  showSetting: false,
  setServer: (server: number) => {},
  setToken: (token: string) => {},
  setLocalUser: (localUser: IUser) => {},
  setSession: (session: any) => {},
  setClassInfo: (classInfo: IClassInfo) => {},
  // setSessionName: (sessionName: string) => {},
  setRemoteUsers: (remoteUsers: IUser[]) => {},
  startRecording: async (sessionId: string) => true,
  stopRecording: async (recordingId: string) => true,
  setShowSetting: (showSetting: boolean) => {},
};
