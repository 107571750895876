import React from 'react';
import Settings from 'components/Settings';

function SettingsPage() {
  return (
    <div style={{ paddingTop: '80px' }}>
      <Settings isPopUp={true} />
    </div>
  );
}
export default SettingsPage;
