export const STUDENT_ISSUES = [
  '선생님이 보이지 않지만, 목소리는 들림',
  '선생님은 보이지만 목소리가 들리지 않음',
  '수업 중간중간 영상이나 음성이 끊김',
  '화상 학습창이 까맣게 바뀜(블랙아웃)',
  '화상 학습창이 강제 종료됨',
  '선생님이 3분이 지나도 교실에 입장하지 않음',
  '나의 모습이 화면에 보이지 않음',
  '선생님이 수업 중간에 나감',
  '기타',
];

export const TUTOR_ISSUES = [
  'Unable to see the student, but can hear the student’s voice',
  'Able to see the student but can not hear the student’s voice',
  'Student\n’s video freezes and/or audio goes in and out',
  'Class screen went black/blank',
  'Class screen suddenly closed completely',
  'Class tool (ex: student pen function) not working (not moving)',
  'Unable to see myself in the class screen',
  'The student has not re-entered after exiting mid-class.',
  'Other',
];
