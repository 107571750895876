import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    themeName?: string; // optional
  }
}

const palette = {
  primary: { main: '#ffffff' },
  secondary: { main: '#fbc61e' },
  // selected: { main: '#fbc61e' },
  action: {
    disabled: '#777777',
  },
};

const themeName = 'XingStyle';

export default createMuiTheme({ palette, themeName });
