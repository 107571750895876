import Tool from './tool';

export default class Pen extends Tool {
  constructor(canvas) {
    super(canvas);
    this.color = 'black';
    this.thickness = 3;
    this.drag = false;
  }

  select() {
    this.addClickEvents();
  }
  deselect() {
    this.removeEvents();
  }
  move(e) {
    let x, y;
    const rect = this.canvas.getBoundingClientRect();
    if (this.drag) {
      if (e.touches) {
        x = e.touches[0].clientX - rect.x;
        y = e.touches[0].clientY - rect.y;
      } else {
        x = e.clientX - rect.x;
        y = e.clientY - rect.y;
      }

      const data = {
        pos: { x, y },
        rect: this.rect,
      };
      this.sendData({ event: 'draw', data });
    }
  }

  setColor(color) {
    this.color = color;
  }
  setThickness(thickness) {
    this.thickness = thickness;
  }

  start(e) {
    let x, y;
    this.drag = true;
    const rect = this.canvas.getBoundingClientRect();
    if (e.touches) {
      x = e.touches[0].clientX - rect.x;
      y = e.touches[0].clientY - rect.y;
    } else {
      x = e.clientX - rect.x;
      y = e.clientY - rect.y;
    }
    const data = {
      pos: { x, y },
      rect: this.rect,
      lineWidth: this.thickness,
      strokeStyle: this.color,
    };
    this.sendData({ event: 'draw start', data });
  }

  paint(message) {
    super.paint(message);
    const { event, data } = message;
    const rect = this.convertPosition(data.pos, data.rect);
    if (event === 'draw start') {
      this.ctx.strokeStyle = data.strokeStyle;
      this.ctx.lineWidth = data.lineWidth;
      this.ctx.lineJoin = 'round';
      this.ctx.lineCap = 'round';
      this.ctx.globalCompositeOperation = 'source-over';
      this.ctx.beginPath();
      this.ctx.moveTo(rect.x, rect.y);
      return;
    }
    if (event === 'draw') {
      this.ctx.lineTo(rect.x, rect.y);
      this.ctx.stroke();
    }
  }
  stop() {
    this.drag = false;
  }
}
