import React, { useContext } from 'react';
import Popover from '@material-ui/core/Popover';
import './stamps.css';
import { sendCommand } from '../../utils/libs';
import VideoCallContext from 'contexts/videocall.context';
import IconButton from '@material-ui/core/IconButton';
import LikeIcon from 'components/Icons/LikeIcon';
export interface IProps {}

function StampSelector() {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const { session, classInfo } = useContext(VideoCallContext);

  const stamps =
    classInfo.service === 'eggplay'
      ? [
          { name: 'Good Job', url: '/stamps/play-goodjob.png' },
          { name: 'Excellent', url: '/stamps/play-excellent.png' },
          { name: 'Correct', url: '/stamps/play-correct.png' },
        ]
      : [
          { name: 'Excellent', url: '/stamps/Excellent.png' },
          { name: 'Good Job', url: '/stamps/GoodJob.png' },
          { name: 'Good Work', url: '/stamps/GoodWork.png' },
          { name: 'Keep It Up', url: '/stamps/KeepItUp.png' },
          { name: 'Way To Go', url: '/stamps/WayToGo.png' },
        ];

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const showStamp = (url: string) => {
    sendCommand(session, {
      command: 'showStamp',
      url,
    });
  };
  return (
    <li>
      <IconButton onClick={handleOpen}>
        <LikeIcon
          style={{ fontSize: 40, opacity: 1 }}
          color={open ? 'secondary' : 'primary'}
        />
      </IconButton>

      {/* <button style={{ width: 58, height: 38 }} onClick={handleOpen}>
        <SVG
          name='like'
          color={open ? Color.default : Color.white}
          viewBox='0 0 24 24'
          width={34}
          height={34}
        />
      </button> */}

      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}>
        <ul id='stampContainer'>
          {stamps.map((stamp) => (
            <li
              className='stampList'
              key={stamp.name}
              onClick={() => showStamp(stamp.url)}>
              <button className='stampsButton'>
                <img
                  className='stampImage'
                  src={stamp.url}
                  alt={stamp.name}
                  style={{ width: '34px', height: '34px' }}
                />
                {/* <span className='stampName'>{stamp.name}</span> */}
              </button>
            </li>
          ))}
        </ul>
      </Popover>
    </li>
  );
}
export default StampSelector;
