import Tool from './tool';

export default class Text extends Tool {
  constructor(canvas) {
    super(canvas);
    this.typing = false;
  }

  select() {
    this.addClickEvents();
  }

  deselect() {
    this.removeEvents();
    this.rasterize();
  }

  start(e) {
    if (this.typing) {
      this.rasterize();
    } else {
      let x, y;
      let posX, posY;
      const rect = this.canvas.getBoundingClientRect();
      if (e.touches) {
        x = e.touches[0].clientX - rect.x;
        y = e.touches[0].clientY - rect.y;
        posX = e.touches[0].clientX;
        posY = e.touches[0].clientY;
      } else {
        x = e.clientX - rect.x;
        y = e.clientY - rect.y;
        posX = e.clientX;
        posY = e.clientY;
      }
      // Creating element for user input
      let textPsuedo = document.createElement('input');
      textPsuedo.type = 'text';
      textPsuedo.classList.add('text-tool-accept');
      document.body.appendChild(textPsuedo);
      textPsuedo.style.left = posX + 'px';
      textPsuedo.style.top = posY - 18.5 + 'px';
      textPsuedo.style.color = 'black';
      textPsuedo.style.fontSize = '24px';
      textPsuedo.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) this.rasterize();
      });
      setTimeout(() => textPsuedo.focus(), 100);
      this.typing = true;
      this.x = x;
      this.y = y;
    }
  }
  removeInput() {
    this.typing = false;
    let textPseudo = document.querySelector('.text-tool-accept');
    if (textPseudo) document.body.removeChild(textPseudo);
  }

  rasterize() {
    let textPseudo = document.querySelector('.text-tool-accept');
    if (textPseudo) {
      this.ctx.fillStyle = '#000000';

      // this.ctx.font = '14px sans-serif';
      // this.ctx.fillText(textPseudo.value, this.x + 11, this.y + 6);
      document.body.removeChild(textPseudo);
      this.typing = false;
      const data = {
        text: textPseudo.value,
        pos: { x: this.x + 14, y: this.y + 20 },
        rect: this.rect,
        fillStyle: this.ctx.fillStyle,
      };
      this.sendData({ event: 'text', data });
    }
  }

  paint(message) {
    super.paint(message);
    const { event, data } = message;
    const rect = this.convertPosition(data.pos, data.rect);
    if (event === 'text') {
      this.ctx.fillStyle = data.fillStyle;
      this.ctx.font = '24px sans-serif';
      this.ctx.fillText(data.text, rect.x, rect.y);
    }
  }
}
