import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import VideoRoom from './VideoRoom';
import VideoCallContext from 'contexts/videocall.context';
import { IClassInfo, IUser } from '../models/videocall.model';
import { useDispatch } from 'react-redux';
import { fetchEpisodePage } from 'store/book.slice';

const EPISODE_ID = 65;

interface IProps {
  userType?: 'tutor' | 'student' | undefined;
}

const getRandomPinNumber = (): string => {
  const rand = Math.random();
  return (rand * 10000).toString().substring(0, 4);
};

function Home({ userType = 'student' }: IProps) {
  const { setServer, localUser, setLocalUser, setClassInfo } =
    useContext(VideoCallContext);
  const dispatch = useDispatch();

  // const [userType, setUserType] = useState<'tutor' | 'student' | undefined>(
  //   'tutor'
  // );
  const [showVideo, setShowVideo] = useState(false);
  const [pinNumber, setPinNumber] = useState('');
  useEffect(() => {
    if (userType === 'tutor') {
      setPinNumber(getRandomPinNumber());
    }
  }, [userType]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const classInfo: IClassInfo = {
      uuid: pinNumber,
      service: 'eggschool-preview',
      classMode: 'PREVIEW',
      capacity: 1,
      className: 'Preview Class',
      bookName: 'Preview Episode',
      episodeId: EPISODE_ID,
    };
    const newUser: IUser = {
      ...localUser,
      userName: 'Tester',
      userType,
      userID: userType,
    };
    setLocalUser(newUser);
    setClassInfo(classInfo);
    setServer(1);
    setShowVideo(true);
    dispatch(fetchEpisodePage(EPISODE_ID.toString()));
  };

  if (showVideo) {
    return <VideoRoom />;
  }

  return (
    <Container>
      <LogContainer>
        <img
          src='https://e.eggschool.net/images/img/img_logo.png'
          alt='eggschool'
          style={{ width: '230px', height: '36px' }}
        />
      </LogContainer>
      <FormContainer>
        <form autoComplete='off' onSubmit={handleSubmit}>
          <PinNumber
            type='text'
            required
            minLength={4}
            maxLength={4}
            placeholder='Pin number를 입력하세요'
            value={pinNumber}
            onChange={(event) => setPinNumber(event.target.value)}
          />
          <Button title='확인' type='submit'>
            확인
          </Button>
        </form>
      </FormContainer>
    </Container>
  );
}

export default Home;

const Container = styled.div`
  /* background-color: white; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const LogContainer = styled.div`
  margin-top: 10rem;
`;

const FormContainer = styled.div`
  margin-top: 4rem;
`;

const PinNumber = styled.input`
  border-width: 1;
  border-color: #ccc;
  width: 20rem;
  height: 3rem;
  font-size: 1.2rem;
  padding-left: 1rem;
`;

const Button = styled.button`
  font-size: 1.2rem;
  width: 10rem;
  background-color: #fbc61f;
  height: 3.1rem;
  border-width: 1;
  margin-left: 1rem;
  color: black;
`;
