import React, { useEffect, useState } from 'react';
import Home from './ Home';

export interface IProps {}

function SystemCheck({}: IProps) {
  return (
    <div>
      <Home userType='tutor' />
    </div>
  );
}
export default SystemCheck;
