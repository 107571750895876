import React, { useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoCallContext from 'contexts/videocall.context';
import { sendCommand } from 'utils/libs';
import { RootState } from '../../store/rootReducer';

interface IProps {
  userType?: string;
  userName?: string;
  userID?: string;
  publisher: any;
  me: boolean;
  audioActive?: boolean;
  videoActive?: boolean;
  onToggleVideo?: () => void;
  onToggleAudio?: () => void;
  onShowSetting?: () => void;
}
function MyVideo({
  userType,
  userName,
  userID,
  publisher,
  me,
  audioActive,
  videoActive,
  onToggleAudio,
  onToggleVideo,
  onShowSetting,
}: IProps) {
  const videoRef = useRef(null);
  const { localUser, session } = useContext(VideoCallContext);
  const { speaker, isZoomIn } = useSelector(
    (state: RootState) => state.videoRoom
  );
  useEffect(() => {
    if (publisher && !!videoRef) {
      publisher.addVideoElement(videoRef.current);
    }
  }, [publisher]);

  const permitWrite = () => {
    if (speaker === localUser.userID) {
      sendCommand(session, {
        command: 'changeSpeaker',
        userID: userID,
      });
    } else {
      sendCommand(session, {
        command: 'changeSpeaker',
        userID: localUser.userID,
      });
    }
  };

  // if (!user || !user.streamManager || !user.streamManager.stream) return null;
  //
  return (
    <div>
      <div className='vFace'>
        {publisher ? (
          <video
            autoPlay={true}
            id={'video-' + publisher.stream.streamId}
            ref={videoRef}
            muted={false}
            className='imgtest'
            height={isZoomIn ? undefined : 213}
            // height={ 213}
          />
        ) : (
          <img src='/images/img_teacher_video.jpg' alt='' className='imgtest' />
        )}
      </div>
      <div className='vUtil'>
        <ul>
          {localUser.userType === 'tutor' && !me && (
            <li>
              <IconButton onClick={permitWrite}>
                <EditIcon
                  color={speaker !== localUser.userID ? 'secondary' : 'primary'}
                  // color={speaker === userID ? Color.default : 'primary'}
                  style={{ fontSize: 20, opacity: 1 }}
                />
              </IconButton>
            </li>
          )}
          <li>
            <IconButton onClick={onToggleAudio}>
              {audioActive ? (
                <MicIcon color='primary' style={{ fontSize: 20, opacity: 1 }} />
              ) : (
                <MicOffIcon
                  color='primary'
                  style={{ fontSize: 20, opacity: 1 }}
                />
              )}
            </IconButton>
          </li>
          <li>
            <IconButton onClick={onToggleVideo}>
              {videoActive ? (
                <VideocamIcon color='primary' style={{ fontSize: 20 }} />
              ) : (
                <VideocamOffIcon color='primary' style={{ fontSize: 20 }} />
              )}
            </IconButton>
          </li>
          {me && (
            <li>
              <IconButton onClick={onShowSetting}>
                <SettingsIcon color='primary' style={{ fontSize: 20 }} />
              </IconButton>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default MyVideo;
