import React from 'react';
import { motion } from 'framer-motion';

export interface IProps {
  url?: string;
}

function Stamp({ url }: IProps) {
  if (!url) return null;
  return (
    <motion.div
      style={{
        position: 'absolute',
        top: window.innerHeight / 2 - 200,
        left: window.innerWidth / 2 - 300,
      }}
      animate={{
        scale: [1, 1.5, 1, 1.5, 1],
        opacity: [1, 1, 1, 1, 1],
        // rotate: [0, 0, 270, 270, 0],
        borderRadius: ['20%', '50%', '20%', '50%', '20%'],
      }}
      initial={{ opacity: 0.5 }}
      exit={{ opacity: 0.5 }}
      transition={{
        duration: 2,
        ease: 'easeInOut',
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: 2,
        repeatDelay: 0.5,
      }}>
      <img src={url} alt='stamp' width='200' height='200' />
    </motion.div>
  );
}
export default Stamp;
