import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import Controller from 'components/Controller';
import Navigation from 'components/Navigation';
import VideoCallContext from 'contexts/videocall.context';
import { IToolMode } from 'models/videoRoom.model';
import { setToolMode } from 'store/videoRoom.slice';

interface IProps {}
// function Footer({ onResize }: IProps) {
const Footer = React.forwardRef(
  (props: IProps, ref: React.Ref<HTMLDivElement>) => {
    const { pages, currentPage } = useSelector(
      (state: RootState) => state.book
    );
    const { isZoomIn, toolMode } = useSelector(
      (state: RootState) => state.videoRoom
    );
    // const { sendCommand } = props;
    const dispatch = useDispatch();
    const { localUser } = useContext(VideoCallContext);
    const handleToolMode = (toolMode: IToolMode) => {
      dispatch(setToolMode(toolMode));
    };
    return (
      <footer ref={ref} className={isZoomIn ? 'expandExe' : ''}>
        <div className='fInbox'>
          <Controller
            setToolMode={handleToolMode}
            localUser={localUser}
            toolMode={toolMode}
          />

          {localUser.userType === 'tutor' && (
            <Navigation totalPage={pages.length} currentPage={currentPage} />
          )}
        </div>
      </footer>
    );
  }
);

export default Footer;
