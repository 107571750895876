import React, { useEffect, useRef, useContext } from 'react';
import { IMedia } from 'models/videoRoom.model';
import { sendCommand } from '../utils/libs';
import VideoCallContext from 'contexts/videocall.context';

export interface IProps {
  media: IMedia;
}

function SongPlayer({ media }: IProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const { session } = useContext(VideoCallContext);
  useEffect(() => {
    if (media.play) {
      audioRef.current?.play();
    } else {
      audioRef.current?.played && audioRef.current?.pause();
    }
  }, [media.play]);

  const onProgress = () => {
    const currentTime = audioRef.current?.currentTime;
    const duration = audioRef.current?.duration;
    const { url, play } = media;
    sendCommand(session, {
      command: 'updateMedia',
      play,
      currentTime,
      duration,
      url,
    });
  };

  const onEnded = () => {
    console.log('onEnded');
    sendCommand(session, {
      command: 'updateMedia',
      play: false,
      currentTime: 0,
      duration: 0,
      url: '',
    });
  };
  return (
    <audio
      src={media.url}
      controls={false}
      autoPlay={false}
      onTimeUpdate={onProgress}
      loop={false}
      onEnded={onEnded}
      ref={audioRef}
    />
  );
}
export default SongPlayer;
