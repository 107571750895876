import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import './Settings.css';
import VideoCallContext from 'contexts/videocall.context';
import { checkNoShow } from 'api/noshow.api';
import { RETURN_URL } from 'config/config';

let tid: any;

function NoShow() {
  const { localUser, classInfo } = useContext(VideoCallContext);
  const [noShow, setNoShow] = useState(false);

  useEffect(() => {
    if (localUser.userType === 'student' && !classInfo.isLevelTest) {
      tid = setInterval(async () => {
        const response = await checkNoShow(classInfo.uuid);
        if (response.data.result === 'noshow') {
          setNoShow(true);
          tid && clearInterval(tid);
        } else if (response.data.result === 'success') {
          tid && clearInterval(tid);
        }
      }, 1000 * 10);
    }
    return () => {
      tid && clearInterval(tid);
    };
  }, [localUser.userType]);

  if (!noShow) return null;
  const handleNoShow = () => {
    window.location.href = `${RETURN_URL.eggschool_student}`;
  };

  return (
    <OverWrapper>
      <div className='popWrap' style={{ opacity: 1, marginTop: '-344px' }}>
        <div className='guTop'>
          <div className='guHead'>
            <div className='guClosed'>
              <button onClick={handleNoShow}>
                <img src='/images/btn_pop_close.png' alt='Close' />
              </button>
            </div>
          </div>
          <div className='guCont'>
            <div className='innerBox'>
              <div className='tit'>수업 취소 안내</div>

              <Description>
                <Title>죄송합니다.</Title> 본 수업은 강사의 미참여로 취소
                되었습니다.
                <br />
                수업권은1시간 이내에 복구될 예정이며, 번거로우시겠지만 다시
                예약해 주시기를 부탁드립니다. 재발 방지를 위해 강사 교육과
                관리에 더욱 노력하겠습니다. 수업 이용에 불편을 드려 다시 한번
                사과의 말씀을 드립니다
              </Description>
            </div>

            <div className='setNoti'>
              <span>
                <button
                  style={{ color: 'white' }}
                  id='close'
                  onClick={handleNoShow}>
                  확인
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </OverWrapper>
  );
}

const Title = styled.p`
  font-size: 2rem;
  margin-bottom: 1rem;
`;
const Description = styled.div`
  padding: 20px 0 0px;
  font-size: 1.4rem;
  line-height: 3rem;
  color: black;
`;
const OverWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
  overflow: hidden;
  z-index: 999;
`;

export default NoShow;
