import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialUserState, IUser } from 'models/users.model';

const UserSlicer = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setPenColor: (state: IUser, action: PayloadAction<string | null>) => {
      const color = action.payload ? action.payload : 'red';
      state.userPen.color = color;
    },
    setPenLineWidth: (state: IUser, action: PayloadAction<string | null>) => {
      const lineWidth = action.payload ? action.payload : '3';
      state.userPen.lineWidth = lineWidth;
    },

    setTextColor: (state: IUser, action: PayloadAction<string>) => {
      state.userText.color = action.payload;
    },

    setShowSurvey: (state: IUser, action: PayloadAction<boolean>) => {
      state.showSurvey = action.payload;
    },
  },
});

export const { setPenColor, setPenLineWidth, setTextColor, setShowSurvey } =
  UserSlicer.actions;

export default UserSlicer.reducer;
