import React from "react";

interface IProps {
  src: string;
  alt: string;
  disabled?: boolean;
  style?: object;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

function ImageButton({
  src,
  alt,
  onClick,
  disabled = false,
  style = {},
}: IProps) {
  return (
    <button onClick={onClick} style={style}>
      <img src={src} alt={alt} />
    </button>
  );
}

export default ImageButton;
