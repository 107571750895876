import axios from 'axios';
import { EGGSCHOOL_API_SERVER } from 'config/config';
import React, { useState } from 'react';

function LoginT() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onEnterSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const url = `${EGGSCHOOL_API_SERVER}/api/auth/user/login`;
    await axios.post(url, { email, password });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
      }}>
      <form onSubmit={onEnterSubmit}>
        <ul>
          <li>
            <input
              type='text'
              placeholder='Email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </li>
          <li>
            <input
              type='password'
              placeholder='Password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </li>
          <li>
            <input type='submit' />
          </li>
        </ul>
      </form>
    </div>
  );
}
export default LoginT;
