/**
 * Open vidu 상대에게 command를 보낸다.
 * @param session : OpenVidu session
 * @param data : command data
 */
import { RETURN_URL } from '../config/config';
import store from '../store';
import { setShowSurvey } from '../store/user.slice';

export const sendCommand = (session: any, data: any) => {
  session.signal({
    data: JSON.stringify(data),
    type: 'command',
  });
};

/**
 * Open Vidu 상대에게 User상태 변경을 보낸다.
 * @param session
 * @param data
 */
export const sendSignalUserChanged = (session: any, data: any) => {
  const signalOptions = {
    data: JSON.stringify(data),
    type: 'userChanged',
  };
  session.signal(signalOptions);
};

export const exitRedirection = (service: string, isLevelTest: boolean, userType: 'tutor' | 'student') => {
  if (service === 'eggplay') {
    window.location.href = 'https://www.eggcomon.com/main';
  } else {
    if (isLevelTest) {
      if (userType === 'tutor') {
        window.location.href = `${RETURN_URL.eggschool_tutor_level_test}`;
      } else {
        store.dispatch(setShowSurvey(true));
      }
    } else {
      if (userType === 'tutor') {
        window.location.href = `${RETURN_URL.eggschool_tutor}`;
      } else {
        window.location.href = `${RETURN_URL.eggschool_student}`;
      }
    }
  }
}
