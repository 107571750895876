import React, { useEffect, useState } from 'react';

export interface IProps {
  startDateTime: string;
}

const initialTime = 25;
const TOTAL_CLASS_SECONDS = 60 * 25;

function Clock({ startDateTime }: IProps) {
  const [seconds, setSeconds] = useState(initialTime);

  useEffect(() => {
    const checkClassTime = () => {
      const currentDateTime = new Date();
      const currentSeconds = currentDateTime.getTime() / 1000;
      const startSeconds = new Date(startDateTime).getTime() / 1000;
      let gapSeconds = currentSeconds - startSeconds;

      if (gapSeconds < 0) {
        setSeconds(TOTAL_CLASS_SECONDS);
      } else if (gapSeconds > TOTAL_CLASS_SECONDS) {
        setSeconds(0);
      } else {
        setSeconds(TOTAL_CLASS_SECONDS - gapSeconds);
      }
    };

    let timeId = setInterval(checkClassTime, 1000 * 1);
    return () => {
      timeId && clearInterval(timeId);
    };
  }, [startDateTime]);

  return <li className='time'>{getFormatTime(seconds)}</li>;
}
export default Clock;

const getFormatTime = (seconds: number): string => {
  let min = parseInt((seconds / 60).toString()).toString();
  if (min.length === 1) min = `0${min}`;
  let sec = parseInt((seconds % 60).toString()).toString();
  if (sec.length === 1) sec = `0${sec}`;

  return `${min}:${sec}`;
};
