import React, { Suspense, useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import './i18n';
import Navigation from 'Navigation';
import VideoCallProvider from 'contexts/videocall.provider';
import { setPenColor, setPenLineWidth } from 'store/user.slice';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const penColor = localStorage.getItem('@penColor')
      ? localStorage.getItem('@penColor')
      : 'red';
    const LineWidth = localStorage.getItem('@penWidth')
      ? localStorage.getItem('@penWidth')
      : '3';

    dispatch(setPenLineWidth(LineWidth));
    dispatch(setPenColor(penColor));
  }, [dispatch]);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <VideoCallProvider>
          <Navigation />
        </VideoCallProvider>
      </SnackbarProvider>
    </Suspense>
  );
}

export default App;
