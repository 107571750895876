import axios, { AxiosResponse } from 'axios';
import { ZOOMUP_API_SERVER } from 'config/config';
import { ISummaryUser } from '../models/users.model';

export const getToken = async (
  sessionName: string,
  user: ISummaryUser
): Promise<AxiosResponse> => {
  return axios.post(`${ZOOMUP_API_SERVER}/api/get-token`, {
    sessionName,
    user,
  });
};

export const removeUser = async (
  sessionName: string
): Promise<AxiosResponse> => {
  return axios.post(`${ZOOMUP_API_SERVER}/api/remove-user`, { sessionName });
};

export const doStartRecording = async (
  session: string
): Promise<AxiosResponse> => {
  const data = {
    session,
    outputMode: 'INDIVIDUAL',
    hasAudio: true,
    hasVideo: true,
  };

  return axios.post(`${ZOOMUP_API_SERVER}/api/recording/start`, data);
};

export const doStopRecording = async (
  sessionId: string
): Promise<AxiosResponse> => {
  const data = {
    sessionId: sessionId,
  };

  return axios.post(`${ZOOMUP_API_SERVER}/api/recording/stop`, data);
};
