import React, { FC } from 'react';
import styled from 'styled-components';

import './Tip.css';
interface IProps {
  visible: boolean;
  closeModal: () => void;
}

const UseTip: FC<IProps> = ({ visible, closeModal }) => {
  if (!visible) return null;
  return (
    <>
      <div id='popWrap' style={{ opacity: 1, marginTop: '-371px' }}>
        <div id='guTBLow'>
          <div id='guHead' className='long'>
            <div className='guClosed'>
              <button onClick={closeModal}>
                <img src='/images/btn_pop_close.png' alt='close tip' />
              </button>
            </div>
          </div>

          <div className='guCont'>
            <div className='innerBox'>
              <div className='tit'>TIPs for the Mock Class!</div>

              <div className='tipsWrap'>
                <div className='tipBox'>
                  <div className='number'>1</div>
                  <div className='tipCont'>
                    <p>Practice before you RECORD!</p>
                    <ul>
                      <li>
                        <span>1</span>Read the lesson plan and understand it
                        fully
                      </li>
                      <li>
                        <span>2</span>Use hand gesture and props to help kids
                        understand
                      </li>
                      <li>
                        <span>3</span>Use your soft voice and don’t speak fast.
                        Kids won’t understand
                      </li>
                      <li>
                        <span>4</span>Show your big smile face. Kids will like
                        it
                      </li>
                      <li>
                        <span>5</span>Check your background noise to prevent
                        class interruption
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='tipBox'>
                  <div className='number'>2</div>
                  <div className='tipCont'>
                    <p>Press the RECORD button once you are ready!</p>
                    <ul>
                      <li>
                        <span>1</span>Ensure background noise is kept to an
                        absolute minimum.
                      </li>
                      <li>
                        <span>2</span>Refer to the Mock class sample video to
                        help you as you go along the lesson.
                      </li>
                      <li>
                        <span>3</span>Make sure you speak clearly and use
                        expressive but appropriate intonation.
                      </li>
                      <li>
                        <span>4</span>Utilize the built-in platform tools
                        (stamp, draw, typing, etc.) and teaching tips to take
                        your teaching to the next level.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='tipBox'>
                  <div className='number'>3</div>
                  <div className='tipCont'>
                    <p>
                      Press the SUBMIT button and send your file when you are
                      finished recording!
                    </p>
                    <ul>
                      <li>
                        <span>1</span>Once you send your recording file,
                        re-sending is impossible.
                      </li>
                      <li>
                        <span>2</span>Record as many times as you need before
                        sending the final version.{' '}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='allBg' style={{ display: 'block' }}></div>
    </>
  );
};

export default UseTip;
