import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './Direction.css';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
export interface IProps {
  direction?: string;
}

function Direction({ direction = '' }: IProps) {
  const [visible, setVisible] = useState(0);
  const showDirection = () => {
    visible === 0 ? setVisible(1) : setVisible(0);
  };

  const { currentPage } = useSelector((state: RootState) => state.book);

  useEffect(() => {
    setVisible(0);
  }, [currentPage]);

  return (
    <>
      <div className='dirBtn'>
        <button onClick={showDirection}>
          <img src='/images/btn_visi_direction.png' alt='' />
        </button>
      </div>
      <motion.div
        animate={{ opacity: visible ? 1 : 0 }}
        className='directionBox'
        style={{ zIndex: visible ? 11 : 1 }}>
        <Wrapper>
          <div
            className='phrase'
            dangerouslySetInnerHTML={{
              __html: direction.replace('\n', '<br />'),
            }}></div>
        </Wrapper>
      </motion.div>

      {/* */}
    </>
  );
}
export default Direction;

const Wrapper = styled.div`
  width: 80%;
  padding: 37px 0;
  /* border-color: #ccc; */
  /* border-style: solid;
  border-width: 3px 3px 0px 3px; */
  border-radius: 10px 10px 0 0;
  margin: 0 auto;
  /* opacity: 0.7; */
  background-color: #000000;
  /* z-index: 999; */
`;

const Phrase = styled.div`
  font-size: 20px;
  color: #ffffff;
  line-height: 1.3;

  word-break: keep-all;
  text-align: left;
  padding: 0 20px 0 20px;
  font-weight: 600;
`;
