import React, { useRef, useEffect, useContext } from 'react';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import { IconButton, Popover } from '@material-ui/core';
import { sendCommand } from '../utils/libs';
import VideoCallContext from 'contexts/videocall.context';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import './SongController.css';
import { IMedia } from '../models/videoRoom.model';

interface IProps {
  media: IMedia;
}
function SongController({ media }: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { url, play } = media;

  const { session } = useContext(VideoCallContext);

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handlePlay = (url: string) => {
    sendCommand(session, {
      command: 'playMedia',
      url,
    });
  };

  const handleStop = (url: string) => {
    sendCommand(session, {
      command: 'updateMedia',
      url: '',
      play: false,
      duration: 0,
      currentTime: 0,
    });
  };

  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    audioRef.current &&
      audioRef.current.addEventListener('playing', (event) => {
        console.log('added event', audioRef.current?.currentTime);
        console.log(event);
      });
  }, [audioRef]);

  return (
    <li>
      <IconButton onClick={handleOpen}>
        <MusicNoteIcon
          style={{ fontSize: 40, opacity: 1 }}
          color={open ? 'secondary' : 'primary'}
        />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}>
        <ul id='stampContainer' className='container'>
          <li className='line'>
            <span className='songName'>Hello Song</span>
            <IconButton
              onClick={
                play
                  ? () =>
                      handleStop(
                        'https://eggschool.s3.ap-northeast-2.amazonaws.com/eggcomon/Hello_song.mp3'
                      )
                  : () =>
                      handlePlay(
                        'https://eggschool.s3.ap-northeast-2.amazonaws.com/eggcomon/Hello_song.mp3'
                      )
              }>
              {url ===
                'https://eggschool.s3.ap-northeast-2.amazonaws.com/eggcomon/Hello_song.mp3' &&
              play ? (
                <StopIcon
                  style={{ fontSize: 30, opacity: 1 }}
                  color={'primary'}
                />
              ) : (
                <PlayArrowIcon
                  style={{ fontSize: 30, opacity: 1 }}
                  color={'primary'}
                />
              )}
            </IconButton>
          </li>
          <li className='list'>
            <span className='songName'>Read Up Song</span>

            <IconButton
              onClick={
                play
                  ? () =>
                      handleStop(
                        'https://eggschool.s3.ap-northeast-2.amazonaws.com/eggcomon/readup_song.mp3'
                      )
                  : () =>
                      handlePlay(
                        'https://eggschool.s3.ap-northeast-2.amazonaws.com/eggcomon/readup_song.mp3'
                      )
              }>
              {url ===
                'https://eggschool.s3.ap-northeast-2.amazonaws.com/eggcomon/readup_song.mp3' &&
              play ? (
                <StopIcon
                  style={{ fontSize: 30, opacity: 1 }}
                  color={'primary'}
                />
              ) : (
                <PlayArrowIcon
                  style={{ fontSize: 30, opacity: 1 }}
                  color={'primary'}
                />
              )}
            </IconButton>
          </li>
        </ul>
      </Popover>
    </li>
  );
}
export default SongController;
