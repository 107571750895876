import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IEpisode,
  IEpisodeState,
  initialEpisodeState,
} from 'models/episode.model';

const episodeSlicer = createSlice({
  name: 'episode',
  initialState: initialEpisodeState,
  reducers: {
    setEpisodes: (state: IEpisodeState, action: PayloadAction<IEpisode[]>) => {
      state.episodes = action.payload;
    },

    setCurrentEpisode: (
      state: IEpisodeState,
      action: PayloadAction<number>
    ) => {
      state.currentEpisode = action.payload;
    },
  },
});

export const { setEpisodes, setCurrentEpisode } = episodeSlicer.actions;
export default episodeSlicer.reducer;
