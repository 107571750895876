import React, { useRef, useEffect, useContext } from 'react';
import Pen from 'whiteboard/pen';
import Text from 'whiteboard/text';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import Eraser from '../../whiteboard/eraser';
import VideoCallContext from 'contexts/videocall.context';
import { IPage, initialPageState } from 'models/book.model';
import './BookView.css';
import Direction from 'components/Direction';
import Timer from 'components/Timer';
interface IProps {
  height?: number;
  width?: number;
  page?: IPage;
}

function BookViewer({
  height = 838,
  width = 592,
  page = initialPageState,
}: IProps) {
  const penRef = useRef<any>(null);
  const textRef = useRef<any>(null);
  const eraserRef = useRef<any>(null);
  const drawCanvasRef = useRef<HTMLCanvasElement>(null);
  const imageCanvasRef = useRef<HTMLCanvasElement>(null);

  const { session, localUser } = useContext(VideoCallContext);
  const { userType, userID } = localUser;

  const { toolMode, speaker, timer, clearScreenHolder } = useSelector(
    (state: RootState) => state.videoRoom
  );

  const { userPen } = useSelector((state: RootState) => state.user);

  const { currentPage } = useSelector((state: RootState) => state.book);

  useEffect(() => {
    const text = textRef.current;
    text && text.removeInput();
  }, [currentPage, textRef]);

  useEffect(() => {
    const pen = new Pen(drawCanvasRef.current);
    const text = new Text(drawCanvasRef.current);
    const eraser = new Eraser(drawCanvasRef.current);
    penRef.current = pen;
    textRef.current = text;
    eraserRef.current = eraser;
  }, []);

  useEffect(() => {
    const pen = penRef.current;
    const text = textRef.current;
    const eraser = eraserRef.current;
    if (session) {
      pen.setSession(session, { width, height });
      text.setSession(session, { width, height });
      eraser.setSession(session, { width, height });
    }
  }, [height, session, width]);

  useEffect(() => {
    const pen = penRef.current;
    pen.setColor(userPen.color);
    pen.setThickness(userPen.lineWidth);
  }, [userPen]);

  useEffect(() => {
    const pen = penRef.current;
    const text = textRef.current;
    const eraser = eraserRef.current;
    pen.deselect();
    text.deselect();
    eraser.deselect();

    if (userID !== speaker) return;

    if (toolMode === 'pen') {
      pen.select();
    } else if (toolMode === 'text') {
      text.select();
    } else if (toolMode === 'eraser') {
      eraser.select();
    }
    return () => {
      pen.deselect();
      text.deselect();
      eraser.deselect();
    };
  }, [toolMode, speaker, userID]);

  useEffect(() => {
    if (page.url) {
      const canvas = imageCanvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        let image = new Image();
        image.src = page.url;
        image.onload = () => {
          ctx && ctx.drawImage(image, 0, 0, width, height);
        };
      }
    }
  }, [height, page, width]);

  useEffect(() => {
    const canvas = drawCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx?.clearRect(0, 0, width, height);
      ctx?.beginPath();
    }
  }, [clearScreenHolder, height, page, width]);
  return (
    <div
      id='book'
      style={{
        width,
        height,
        position: 'relative',
      }}>
      {timer > -1 && <Timer />}
      {userType === 'tutor' && page.direction && page.direction.trim() && (
        <Direction direction={page.direction} />
      )}

      <canvas
        draggable={false}
        ref={imageCanvasRef}
        style={{ background: 'white', position: 'absolute', inset: '0px' }}
        width={width}
        height={height}
      />

      <canvas
        draggable={false}
        ref={drawCanvasRef}
        style={{ position: 'absolute', inset: '0px', zIndex: 1 }}
        width={width}
        height={height}
      />
    </div>
  );
}

export default BookViewer;

/**
 * Canvas Overlay :
 *  https://www.mtmckenna.com/posts/2018/02/02/creating-a-canvas-overlay
 * Dynamic Canvas Overlay :
 *  https://docs.microsoft.com/en-us/bingmaps/v8-web-control/map-control-concepts/custom-overlays/dynamic-canvas-overlay
 */
