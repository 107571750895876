import React from 'react';
import { Helmet } from 'react-helmet';

export interface IProps {
  service: string;
}

function Seo({ service }: IProps) {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{service === 'eggplay' ? 'EGG COMON ' : 'Egg School'}</title>
    </Helmet>
  );
}
export default Seo;
