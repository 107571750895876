import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getEpisodePage } from 'api/classInfo.api';

import { IPage, IBookState, initialBookState } from 'models/book.model';

const bookSlicer = createSlice({
  name: 'book',
  initialState: initialBookState,
  reducers: {
    getEpisodePageStart: (state: IBookState) => {
      state.error = null;
      state.loading = true;
    },
    getEpisodePageSuccess: (
      state: IBookState,
      action: PayloadAction<IPage[]>
    ) => {
      state.pages = action.payload;
      state.error = null;
      state.loading = false;
    },
    getEpisodePageFailure: (
      state: IBookState,
      action: PayloadAction<IPage[]>
    ) => {
      state.error = action.payload;
      state.loading = false;
    },
    setCurrentPage: (state: IBookState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const {
  setCurrentPage,
  getEpisodePageStart,
  getEpisodePageSuccess,
  getEpisodePageFailure,
} = bookSlicer.actions;
export default bookSlicer.reducer;

export const fetchEpisodePage =
  (episodeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEpisodePageStart());
      const response = await getEpisodePage(episodeId);
      dispatch(getEpisodePageSuccess(response.data));
      // dispatch(setCurrentEpisode(Number(episodeId)));
    } catch (error) {
      console.log(error);
      dispatch(getEpisodePageFailure(error.toString()));
    }
  };
