import React, { useContext, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IPage } from 'models/book.model';
import { RootState } from 'store/rootReducer';
import VideoCallContext from 'contexts/videocall.context';
import { setShowBookMark } from 'store/videoRoom.slice';
import { sendCommand } from 'utils/libs';

export interface IProps {
  pages: IPage[];
}

function BookMark({ pages }: IProps) {
  const settings = {
    dots: false,
    infinite: false,
    variableWidth: true,
    swipeToSlide: true,
    arrows: true,
    slidesToShow: 1,
  };
  const refBookMark = useRef<any>(null);
  const { showBookMark } = useSelector((state: RootState) => state.videoRoom);
  const { currentPage } = useSelector((state: RootState) => state.book);

  const { session } = useContext(VideoCallContext);
  const dispatch = useDispatch();

  const handlePrev = () => {
    refBookMark.current.slickPrev();
  };
  const handleNext = () => {
    refBookMark.current.slickNext();
  };

  const handleClick = (index: number) => {
    sendCommand(session, { command: 'setPage', index });
    dispatch(setShowBookMark(!showBookMark));
  };

  return (
    <div className='bookmarkBox'>
      <div className='bookSlide'>
        <Slider {...settings} ref={refBookMark}>
          {pages.map((page, index) => (
            <div
              className='slick-slide'
              key={page.id}
              onDoubleClick={() => handleClick(index)}>
              <img
                src={page.url}
                alt='book thumbnail'
                className='bookThumbnail'
              />
            </div>
          ))}
        </Slider>
        <button className='prevRBtn' onClick={handlePrev}>
          <img src='/images/btn_bookmark_prev.png' alt='prev' />
        </button>
        <button className='nextRBtn' onClick={handleNext}>
          <img src='/images/btn_bookmark_next.png' alt='next' />
        </button>
      </div>
    </div>
  );
}
export default BookMark;
