import Home from 'components/ Home';
import GateWay from 'components/GateWay';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginT from 'components/LoginT';
import LoginS from 'components/LoginS';
import SettingsPage from 'containers/settingsPage';
import SystemCheck from '../components/SystemCheck';

function Navigation() {
  return (
    <Router>
      <Switch>
        <Route path='/:service/:mode/:uuid'>
          <GateWay />
        </Route>

        <Route path='/login-t'>
          <LoginT />
        </Route>

        <Route path='/login-s'>
          <LoginS />
        </Route>

        <Route path='/settings'>
          <SettingsPage />
        </Route>

        <Route path='/systemcheck'>
          <SystemCheck />
        </Route>

        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default Navigation;
