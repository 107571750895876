import React, { useContext, useEffect } from 'react';
import { IToolMode } from 'models/videoRoom.model';
import { IUser } from 'models/videocall.model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { setShowBookMark } from 'store/videoRoom.slice';
import VideoCallContext from 'contexts/videocall.context';
import StampSelector from 'components/Stamps';
import { TimerButton } from './Timer';
import { sendCommand } from 'utils/libs';
import PaletteIcon from '@material-ui/icons/Palette';
import IconButton from '@material-ui/core/IconButton';
import { CirclePicker } from 'react-color';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import Popover from '@material-ui/core/Popover';
import LinePicker from './UI/LinePicker';
import { setPenColor, setPenLineWidth } from 'store/user.slice';
import PenIcon from './Icons/PenIcon';
import TextIcon from './Icons/TextIcon';
import EraseIcon from './Icons/EraseIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import BookmarkIcon from './Icons/BoomarkIcon';
import SongController from './SongController';
import SongPlayer from './SongPlayer';

interface IProps {
  localUser: IUser;
  setToolMode: (toolMode: IToolMode) => void;
  toolMode: IToolMode;
}

const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#000000',
];

function Controller({ localUser, setToolMode, toolMode }: IProps) {
  const { userType } = localUser;
  const dispatch = useDispatch();
  const { speaker } = useSelector((state: RootState) => state.videoRoom);
  const { currentPage } = useSelector((state: RootState) => state.book);
  const { showBookMark, media } = useSelector(
    (state: RootState) => state.videoRoom
  );
  const { session, classInfo } = useContext(VideoCallContext);
  const { service } = classInfo;

  const [anchorPalette, setAnchorPalette] = React.useState<any>(null);
  const [anchorLineWidth, setAnchorLineWidth] = React.useState<any>(null);

  useEffect(() => {
    dispatch(setShowBookMark(false));
  }, [currentPage, dispatch]);
  const toggleBookMark = () => {
    dispatch(setShowBookMark(!showBookMark));
  };
  const iAmSpeaker = speaker === localUser.userID;
  const eraseAll = () => {
    sendCommand(session, {
      command: 'eraseAll',
    });
  };

  const handleToolMode = (newToolMode: IToolMode) => {
    if (toolMode === newToolMode) {
      setToolMode(IToolMode.NONE);
    } else {
      setToolMode(newToolMode);
    }
  };

  const handlePalette = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPalette(event.currentTarget);
  };

  const handleClosePalette = () => {
    setAnchorPalette(null);
  };

  const handleCloseLineWidth = () => {
    setAnchorLineWidth(null);
  };
  const openPalette = Boolean(anchorPalette);
  const paletteId = openPalette ? 'palette-popover' : undefined;

  const handleLineWidth = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorLineWidth(event.currentTarget);
  };
  const openLineWidth = Boolean(anchorLineWidth);
  const lineWidthId = openPalette ? 'lineWidth-popover' : undefined;

  const handleChangeColor = (color: any) => {
    dispatch(setPenColor(color.hex));
    localStorage.setItem('@penColor', color.hex);
    handleClosePalette();
  };
  const handleLineWidthChange = (lineWidth: string) => {
    dispatch(setPenLineWidth(lineWidth));
    localStorage.setItem('@penWidth', lineWidth);
    handleCloseLineWidth();
  };

  return (
    <div className='bomUtil'>
      <ul>
        {userType === 'tutor' && <StampSelector />}

        <li>
          <ul className='dlist'>
            {service !== 'eggplay' && (
              <li>
                <IconButton onClick={() => handleToolMode(IToolMode.TEXT)}>
                  <TextIcon
                    style={{ fontSize: 40, opacity: 1 }}
                    color={
                      !iAmSpeaker
                        ? 'disabled'
                        : toolMode === IToolMode.TEXT
                        ? 'secondary'
                        : 'primary'
                    }
                  />
                </IconButton>
              </li>
            )}

            <li>
              <IconButton onClick={() => handleToolMode(IToolMode.PEN)}>
                <PenIcon
                  style={{ fontSize: 40, opacity: 1 }}
                  color={
                    !iAmSpeaker
                      ? 'disabled'
                      : toolMode === IToolMode.PEN
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </IconButton>
            </li>

            <li>
              <IconButton onClick={handlePalette}>
                <PaletteIcon
                  style={{ fontSize: 40, opacity: 1 }}
                  color={
                    !iAmSpeaker
                      ? 'disabled'
                      : openPalette
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </IconButton>
            </li>

            <li>
              <IconButton onClick={handleLineWidth}>
                <LineWeightIcon
                  style={{ fontSize: 40, opacity: 1 }}
                  color={
                    !iAmSpeaker
                      ? 'disabled'
                      : openLineWidth
                      ? 'secondary'
                      : 'primary'
                  }></LineWeightIcon>
              </IconButton>
            </li>

            <li>
              <IconButton onClick={() => handleToolMode(IToolMode.ERASER)}>
                <EraseIcon
                  style={{ fontSize: 40, opacity: 1 }}
                  // color={!iAmSpeaker ? 'disabled' : 'primary'}
                  color={
                    !iAmSpeaker
                      ? 'disabled'
                      : toolMode === IToolMode.ERASER
                      ? 'secondary'
                      : 'primary'
                  }></EraseIcon>
              </IconButton>
            </li>

            <li>
              <IconButton onClick={eraseAll}>
                <DeleteIcon
                  style={{ fontSize: 40, opacity: 1 }}
                  color={!iAmSpeaker ? 'disabled' : 'primary'}></DeleteIcon>
              </IconButton>
            </li>
          </ul>
        </li>

        {userType === 'tutor' && <TimerButton />}

        {userType === 'tutor' && (
          <li>
            <IconButton onClick={toggleBookMark}>
              <BookmarkIcon
                style={{ fontSize: 40, opacity: 1 }}
                color={!iAmSpeaker ? 'disabled' : 'primary'}></BookmarkIcon>
            </IconButton>
          </li>
        )}

        {userType === 'tutor' && service === 'eggplay' && (
          //
          <SongController media={media} />
        )}
      </ul>

      {userType === 'tutor' && service === 'eggplay' && (
        //
        <SongPlayer media={media} />
      )}
      <Popover
        id={paletteId}
        open={openPalette}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorPalette}
        onClose={handleClosePalette}>
        <div
          style={{
            padding: '20px',
            backgroundColor: '#efefef',
          }}>
          <CirclePicker colors={colors} onChangeComplete={handleChangeColor} />
        </div>
      </Popover>

      <Popover
        id={lineWidthId}
        open={openLineWidth}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorLineWidth}
        onClose={handleCloseLineWidth}>
        <div
          style={{
            padding: '20px',
            backgroundColor: '#efefef',
          }}>
          <LinePicker onChange={handleLineWidthChange} />
        </div>
      </Popover>
    </div>
  );
}

export default Controller;
