import Tool from './tool';

export default class Eraser extends Tool {
  constructor(canvas) {
    super(canvas);
    this.color = 'rgba(0,0,0,0.5)';
    // this.color = 'rgba(239,239,239,1)';
    this.thickness = 20;
    this.drag = false;
  }

  select() {
    this.canvas.classList.add('eraser');
    this.addClickEvents();
  }

  deselect() {
    this.canvas.classList.remove('eraser');
    this.removeEvents();
  }

  move(e) {
    let x, y;
    const rect = this.canvas.getBoundingClientRect();
    if (this.drag) {
      if (e.touches) {
        x = e.touches[0].clientX - rect.x;
        y = e.touches[0].clientY - rect.y;
      } else {
        x = e.clientX - rect.x;
        y = e.clientY - rect.y;
      }
      const data = {
        pos: { x, y },
        rect: this.rect,
      };
      this.sendData({ event: 'erase', data });
    }
  }

  start(e) {
    let x, y;
    // this.ctx.strokeStyle = this.color;
    // this.ctx.lineWidth = this.thickness;
    // this.ctx.lineJoin = 'round';
    // this.ctx.lineCap = 'round';
    // this.ctx.globalCompositeOperation = "destination-out";
    this.drag = true;
    const rect = this.canvas.getBoundingClientRect();
    if (e.touches) {
      x = e.touches[0].clientX - rect.x;
      y = e.touches[0].clientY - rect.y;
    } else {
      x = e.clientX - rect.x;
      y = e.clientY - rect.y;
    }
    const data = {
      pos: { x, y },
      rect: this.rect,
      lineWidth: this.thickness,
      strokeStyle: this.color,
    };
    this.sendData({ event: 'erase start', data });
  }

  paint(message) {
    super.paint(message);
    const { event, data } = message;
    const rect = this.convertPosition(data.pos, data.rect);
    if (event === 'erase start') {
      this.ctx.strokeStyle = data.strokeStyle;
      this.ctx.lineWidth = data.lineWidth;
      this.ctx.lineJoin = 'round';
      this.ctx.lineCap = 'round';
      this.ctx.globalCompositeOperation = 'destination-out';
      this.ctx.beginPath();
      this.ctx.moveTo(rect.x, rect.y);
      return;
    }
    if (event === 'erase') {
      this.ctx.lineTo(rect.x, rect.y);
      this.ctx.stroke();
    }
  }

  stop() {
    this.drag = false;
  }
}
