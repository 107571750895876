export interface IPage {
  id: number;
  pageNo: number;
  url: string;
  pageType: 'image' | 'video';
  direction: string;
}

export interface IBookState {
  name: string;
  currentPage: number;
  pages: IPage[];
  loading: boolean;
  error: any;
}

export const initialBookState: IBookState = {
  name: '',
  currentPage: 0,
  pages: [],
  loading: false,
  error: null,
};

export const initialPageState: IPage = {
  id: -1,
  pageNo: -1,
  url: '',
  pageType: 'image',
  direction: '',
};
