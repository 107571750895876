import React, { useContext, useEffect, useState, useRef } from 'react';
import VideoCallContext from 'contexts/videocall.context';
import { useTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { setShowTip, startClock } from 'store/videoRoom.slice';
import Episodes from './Episodes';
import { sendClassComplete } from 'api/classInfo.api';
import { RETURN_URL } from 'config/config';
import { STUDENT_ISSUES, TUTOR_ISSUES } from 'config/constants';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import { IEpisode } from '../models/episode.model';
import './Header.css';
import { sendIssue } from '../api/classInfo.api';
import Clock from './Clock';
import { setShowSurvey } from 'store/user.slice';
import { exitRedirection } from '../utils/libs';

interface IProps {
  onFullScreen: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onLeave: () => void;
}

const Header = React.forwardRef(
  (props: IProps, ref: React.Ref<HTMLElement>) => {
    const {
      startRecording,
      stopRecording,
      session,
      recordingId,
      isRecorded,
      localUser,
      classInfo,
    } = useContext(VideoCallContext);
    const dispatch = useDispatch();
    const [isRecording, setIsRecording] = useState(false);
    const [issue, setIssue] = useState('');
    const [episode, setEpisode] = useState<IEpisode | undefined>(undefined);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const { showTip } = useSelector((state: RootState) => state.videoRoom);
    const { episodes, currentEpisode } = useSelector(
      (state: RootState) => state.episode
    );
    const { enqueueSnackbar } = useSnackbar();
    const {
      uuid,
      service,
      classMode,
      stepName,
      className,
      bookName,
      scheduleId,
      isLevelTest,
      startDateTime,
    } = classInfo;

    useEffect(() => {
      const episode = episodes.find(
        (episode) => Number(episode.episodeId) === currentEpisode
      );
      setEpisode(episode);
    }, [currentEpisode, episodes]);

    const handleOpenSiren = (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseSiren = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleExit = async () => {
      if (isLevelTest === undefined || !localUser?.userType) return;
      exitRedirection(service, isLevelTest, localUser.userType);
    };

    const handleStartRecording = async () => {
      dispatch(startClock());
      const result = await startRecording(session.sessionId);

      if (result) {
        enqueueSnackbar(t('recording_success'), {
          variant: 'success',
        });
        setIsRecording(true);
      } else {
        enqueueSnackbar(t('recording_fail'), {
          variant: 'error',
        });
        setIsRecording(false);
      }
    };
    const handleStopRecording = async () => {
      if (session.sessionId) {
        await stopRecording(session.sessionId);
        setIsRecording(false);
        dispatch(startClock());
      }
    };

    const handleSubmit = async () => {
      if (!isRecorded) {
        enqueueSnackbar(t('you_must_recording'), {
          variant: 'info',
        });
        return false;
      }

      // eslint-disable-next-line no-restricted-globals
      const result = confirm(t('submit_confirm'));
      if (!result) return;

      try {
        await sendClassComplete(uuid);
        enqueueSnackbar(t('submit_success'), {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(t('submit_fail'), {
          variant: 'error',
        });
      }
      if (RETURN_URL.eggschool_mock)
        window.location.href = RETURN_URL.eggschool_mock;
    };

    const handleTip = () => {
      dispatch(setShowTip(!showTip));
    };

    const handleIssueSubmit = async () => {
      if (issue === '') {
        enqueueSnackbar(t('issue_choice'), {
          variant: 'warning',
        });
        return;
      }
      try {
        const payload = { scheduleId, issue };
        await sendIssue(payload);
        enqueueSnackbar(t('issue_sended'), {
          variant: 'success',
        });
      } catch (error) {
        console.log(error);
      }
      handleIssueClose();
    };
    const handleIssueClose = () => {
      setIssue('');
      setAnchorEl(null);
    };

    const handleIssueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIssue((event.target as HTMLInputElement).value);
    };

    return (
      <header ref={ref}>
        <div id='headerTitle'>
          <h1>
            <img
              src={
                service === 'eggplay'
                  ? '/images/eggplay_logo.png'
                  : '/images/img_logo.png'
              }
              alt='egg school logo'
            />
          </h1>
          {isLevelTest ? (
            <div className='titleBox'>
              <span id='stepName'>Trial Class</span>
              <span id='playTitle'></span>
              <span id='subject'>{episode ? episode.bookName : bookName}</span>
            </div>
          ) : (
            <div className='titleBox'>
              <span id='stepName'>{stepName ? `[${stepName}] ` : ''} </span>
              <span id='playTitle'>
                {episode ? episode.className : className}
              </span>
              <span id='subject'>{episode ? episode.bookName : bookName}</span>
            </div>
          )}
        </div>
        {classMode === 'MOCK' && service === 'eggschool' && (
          <div id='mockController'>
            <button onClick={handleTip}>
              <img src='/images/btn_tip.png' alt='tip' />
            </button>

            {isRecording ? (
              <button onClick={handleStopRecording}>
                <img src='/images/btn_stop.png' alt='stop recording' />
              </button>
            ) : (
              <button onClick={handleStartRecording}>
                <img src='/images/btn_record.png' alt='start recording' />
              </button>
            )}
            {recordingId && !isRecording ? (
              <button onClick={handleSubmit}>
                <img src='/images/btn_submit.png' alt='submit' />
              </button>
            ) : (
              <button>
                <img src='/images/btn_disable_submit.png' alt='submit' />
              </button>
            )}
          </div>
        )}

        {episodes.length > 1 && localUser.userType === 'tutor' && (
          <div id='episodeContainer'>
            <Episodes episodes={episodes} />
          </div>
        )}

        <div id='UtilBox'>
          <ul id='list'>
            {service === 'eggschool' &&
              classMode === 'CLASSROOM' &&
              startDateTime && <Clock startDateTime={startDateTime} />}

            <li>
              <button onClick={props.onFullScreen}>
                <img src='/images/btn_top_expand.png' alt='' />
              </button>
            </li>

            {service === 'eggschool' && localUser.userType === 'student' && (
              <li id='siren'>
                <button onClick={handleOpenSiren}>
                  <img
                    src='/images/siren1.png'
                    alt='issue call'
                    width='30'
                    height='30'
                    color='red'
                  />
                </button>
                &nbsp;Help
              </li>
            )}

            {classMode !== 'MOCK' && (
              <li>
                <button onClick={handleExit}>
                  <img src='/images/btn_top_out.png' alt='' />
                </button>
              </li>
            )}
          </ul>
        </div>
        <Popover
          id={id}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handleCloseSiren}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}>
          <div id='sirenContainer'>
            <div id='sirenTitle'>{t('issue_title')}</div>
            <div>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Gender</FormLabel>
                <RadioGroup
                  aria-label='gender'
                  name='gender1'
                  value={issue}
                  onChange={handleIssueChange}>
                  {localUser.userType === 'student'
                    ? STUDENT_ISSUES.map((item, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={item}
                            control={<Radio />}
                            label={item}
                          />
                        );
                      })
                    : TUTOR_ISSUES.map((item, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={item}
                            control={<Radio />}
                            label={item}
                          />
                        );
                      })}
                </RadioGroup>
              </FormControl>
            </div>
            <div id='btnContainer'>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                onClick={handleIssueSubmit}>
                {t('submit')}
              </Button>
              <div style={{ width: '20px' }}> </div>
              <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={handleIssueClose}>
                {t('cancel')}
              </Button>
            </div>
          </div>
        </Popover>
      </header>
    );
  }
);

export default Header;
