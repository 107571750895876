export const ZOOMUP_API_SERVER = process.env.REACT_APP_ZOOMUP_API_SERVER;
export const EGGSCHOOL_API_SERVER = process.env.REACT_APP_EGGSCHOOL_API_SERVER;
export const EGGPLAY_API_SERVER = process.env.REACT_APP_EGGPLAY_API_SERVER;
export const ZOOMUP_WEBSOCKET = process.env.REACT_APP_ZOOMUP_WEBSOCKET;

export const RETURN_URL = {
  eggschool_mock: process.env.REACT_APP_EGGSCHOOL_MOCK_URL,
  eggschool_tutor: process.env.REACT_APP_EGGSCHOOL_TUTOR_URL,
  eggschool_student: process.env.REACT_APP_EGGSCHOOL_STUDENT_URL,
  eggschool_level_test: process.env.REACT_APP_EGGSCHOOL_LEVEL_TEST_URL,
  eggschool_tutor_level_test:
    process.env.REACT_APP_EGGSCHOOL_TUTOR_LEVEL_TEST_URL,
};
