import React from "react";
import { IChatMessage } from "models";

interface IProps {
  chat: IChatMessage;
  me: boolean;
}
function Chat({ chat, me }: IProps) {
  return (
    <div className="talkDiv">
      <div className={me ? "rTalk" : "lTalk"}>
        <div className="talkbox">{chat.message}</div>
      </div>
    </div>
  );
}

export default Chat;
