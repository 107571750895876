import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import './Settings.css';
import { useDispatch } from 'react-redux';
import { setShowSurvey } from 'store/user.slice';

const url = 'https://forms.gle/mDr3X4owsu3rAxt89';
function Survey() {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setShowSurvey(false));
  };
  const handleSurvey = () => {
    window.location.href = url;
  };

  return (
    <>
      <div className='popWrap' style={{ opacity: 1, marginTop: '-344px' }}>
        <div className='guTop'>
          <div className='guHead'>
            <div className='guClosed'>
              <button onClick={handleClose}>
                <img src='/images/btn_pop_close.png' alt='Close' />
              </button>
            </div>
          </div>

          <div className='guCont'>
            <div className='innerBox'>
              <div className='tit'>체험 수업 설문 조사</div>

              <div className='testDiv'>
                <Desc>
                  우리 아이에게 맞는 체험수업을 진행하고 정확한 결과를 안내해
                  드리기 위하여 설문조사를 진행합니다. 아래 링크를 통하여 작성
                  부탁드립니다.
                </Desc>

                <UrlBox>
                  URL :{' '}
                  <a href='https://forms.gle/mDr3X4owsu3rAxt89'>
                    https://forms.gle/mDr3X4owsu3rAxt89
                  </a>
                </UrlBox>
              </div>

              <div className='setNoti'>
                <span>
                  <button
                    style={{ color: 'white' }}
                    id='close'
                    onClick={handleSurvey}>
                    설문조사 하러 가기
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverWrapper />
    </>
  );
}

const OverWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  overflow: hidden;
  z-index: 999;
`;

const Desc = styled.div`
  color: black;
  margin: 20px 10px 20px 10px;
  font-size: 1.5rem;
  line-height: 2.5rem;
`;

const UrlBox = styled.div`
  color: black !important;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.5rem;

  & > a {
    color: black;
  }
`;

export default Survey;
