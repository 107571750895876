import { combineReducers } from '@reduxjs/toolkit';
import videoRoom from './videoRoom.slice';
import book from './book.slice';
import episode from './episode.slice';
import user from './user.slice';
const rootReducer = combineReducers({
  videoRoom,
  book,
  episode,
  user,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
