import React, { useContext } from 'react';
import ImageButton from 'components/UI/ImageButton';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import VideoCallContext from 'contexts/videocall.context';
import { sendCommand } from 'utils/libs';
interface IProps {
  totalPage: number;
  currentPage: number;
}

/**
 * ToDo : Next, Prev  시 range 넘어 갔을 경우, 경고 보내기
 * */
function Navigation({ totalPage, currentPage }: IProps) {
  const { session } = useContext(VideoCallContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onPrev = () => {
    if (currentPage <= 0) {
      enqueueSnackbar(t('first_page_info'), { variant: 'info' });
      return;
    }
    const page = currentPage - 1;
    sendCommand(session, { command: 'setPage', index: page });
  };
  const onNext = () => {
    if (currentPage + 1 >= totalPage) {
      enqueueSnackbar(t('last_page_info'), { variant: 'info' });
      return;
    }
    const page = currentPage + 1;
    sendCommand(session, { command: 'setPage', index: page });
  };
  return (
    <div className='bomPage'>
      <ImageButton
        src='/images/btn_page_prev.png'
        alt='Input text'
        onClick={onPrev}
        style={{ float: 'left' }}
      />

      <span>
        {currentPage + 1}/{totalPage}
      </span>

      <ImageButton
        src='/images/btn_page_next.png'
        alt='Input text'
        onClick={onNext}
        style={{ float: 'right' }}
      />
    </div>
  );
}

export default Navigation;
