import React, { useEffect, useState } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export interface IProps {}

function PenIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M19.3,4L16,0.7c-0.9-0.9-2.6-0.9-3.5,0L0.8,12.3L0,20l7.7-0.9L19.3,7.5C19.7,7.1,20,6.4,20,5.8  C20,5.1,19.7,4.5,19.3,4z M6.9,14.5l6.4-6.4l1.3,1.3l-6.8,6.8C7.6,15.6,7.3,15,6.9,14.5z M5.5,13.1c-0.5-0.4-1.1-0.7-1.7-0.9  l6.8-6.8l1.3,1.3L5.5,13.1z M2.7,14c1.7,0.3,3,1.7,3.3,3.3l-3.8,0.4L2.7,14z M17.9,6.1L16,8l-4-4l1.9-1.9C14,2,14.2,2,14.2,2  c0.1,0,0.2,0,0.4,0.1l3.3,3.3C18,5.5,18,5.7,18,5.8C18,5.8,18,6,17.9,6.1z'></path>
    </SvgIcon>
  );
}
export default PenIcon;
