import React, { useRef, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import VideoCallContext from 'contexts/videocall.context';
import AudioAnalyser from 'utils/audio/AudioAnalyser';
import './Settings.css';
interface IProps {
  onClose?: () => void;
  isPopUp?: boolean;
  showSiren?: boolean;
}

function Settings({ onClose, isPopUp = false, showSiren = false }: IProps) {
  const [audio, setAudio] = useState<any>(null);
  const [videoInputs, setVideoInputs] = useState<any[]>([]);
  const [audioInputs, setAudioInputs] = useState<any[]>([]);
  const [audioOutputs, setAudioOutputs] = useState<any[]>([]);
  const { localUser, setLocalUser } = useContext(VideoCallContext);
  const { t } = useTranslation();

  const { selectedAudioInput, selectedAudioOutput, selectedVideoInput } =
    localUser;

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoInputRef = useRef<HTMLSelectElement>(null);
  const audioInputRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    const initDevice = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
      } catch (error) {
        alert(
          '브라우저에서 카메라 엑세스 권한을 허용해 주시후 새로 고침해 주시기 바랍니다.'
        );
      }

      const devices: any[] = await navigator.mediaDevices.enumerateDevices();
      const audioInputs = devices.filter(
        (device) => device.kind === 'audioinput'
      );
      setAudioInputs(audioInputs);
      const audioOutputs = devices.filter(
        (device) => device.kind === 'audiooutput'
      );
      setAudioOutputs(audioOutputs);
      const videoInputs = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      setVideoInputs(videoInputs);
      const newVideoInput = selectedVideoInput
        ? selectedVideoInput
        : videoInputs[0].deviceId;
      const newAudioInput = selectedAudioInput
        ? selectedAudioInput
        : audioInputs[0].deviceId;
      if (!selectedAudioInput) {
        setLocalUser({
          ...localUser,
          selectedVideoInput: newVideoInput,
          selectedAudioInput: newAudioInput,
        });
      }
    };
    initDevice();
  }, []);

  useEffect(() => {
    if (selectedVideoInput) {
      changeVideoDevice(selectedVideoInput);
    } else if (videoInputs.length > 1) {
      changeVideoDevice(videoInputs[0].deviceId);
    }
  }, [selectedVideoInput, videoInputs]);

  useEffect(() => {
    if (selectedAudioInput) {
      changeAudioInputDevice(selectedAudioInput);
    } else if (audioInputs.length > 1) {
      changeAudioInputDevice(audioInputs[0].deviceId);
    }
  }, [selectedAudioInput, audioInputs]);

  const changeAudioInputDevice = async (deviceId: string) => {
    const audio = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: deviceId,
      },
      video: false,
    });
    setAudio(audio);
  };

  const changeVideoDevice = async (deviceId: string) => {
    const video = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: deviceId,
        width: 160,
        height: 120,
      },
    });
    if (videoRef.current) {
      videoRef.current.srcObject = video;
    }
  };

  const onChangeVideoInput = (deviceId: string) => {
    setLocalUser({
      ...localUser,
      selectedVideoInput: deviceId,
    });
  };

  const onChangeAudioInput = (deviceId: string) => {
    console.log('xing-log deviceid :', deviceId);
    setLocalUser({
      ...localUser,
      selectedAudioInput: deviceId,
    });
  };

  const onChangeAudioOutput = (deviceId: string) => {
    setLocalUser({
      ...localUser,
      selectedAudioOutput: deviceId,
    });
  };

  const handleClose = () => {
    if (isPopUp) {
      // eslint-disable-next-line no-restricted-globals
      self.close();
    } else {
      onClose && onClose();
    }
  };

  return (
    <>
      <div className='popWrap' style={{ opacity: 1, marginTop: '-344px' }}>
        <div className='guTop'>
          <div className='guHead'>
            <div className='guClosed'>
              <button onClick={handleClose}>
                <img src='/images/btn_pop_close.png' alt='Close' />
              </button>
            </div>
          </div>

          <div className='guCont'>
            <div className='innerBox'>
              <div className='tit'>{t('setting')}</div>
              <div className='testDiv'>
                <div className='tDtop'>
                  <div className='ico'>
                    <img src='/images/ico_setting_camera.png' alt='' />
                  </div>
                  <div className='test'>
                    <p>{t('camera_check')}</p>
                    <div className='search'>
                      <select
                        ref={videoInputRef}
                        className='select'
                        onChange={(event) =>
                          onChangeVideoInput(event.target.value)
                        }
                        value={selectedVideoInput}>
                        {videoInputs.map((videoInput) => (
                          <option
                            value={videoInput.deviceId}
                            key={videoInput.deviceId}>
                            {videoInput.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='cameraArea'>
                    <video
                      autoPlay={true}
                      ref={videoRef}
                      muted={true}
                      style={{ width: 107, height: 80 }}
                    />
                  </div>
                </div>
                <div className='line'></div>
              </div>

              <div className='testDiv'>
                <div className='tDtop'>
                  <div className='ico'>
                    <img src='/images/ico_setting_mic.png' alt='' />
                  </div>
                  <div className='test'>
                    <p>{t('mic_check')}</p>
                    <div className='search'>
                      <select
                        ref={audioInputRef}
                        className='select'
                        onChange={(event) =>
                          onChangeAudioInput(event.target.value)
                        }
                        value={selectedAudioInput}>
                        {audioInputs.map((audioInput) => (
                          <option
                            value={audioInput.deviceId}
                            key={audioInput.deviceId}>
                            {audioInput.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='volumeArea'>
                    {audio ? <AudioAnalyser audio={audio} /> : null}
                  </div>
                </div>
                <div className='line'></div>
              </div>

              <div className='testDiv'>
                <div className='tDtop'>
                  <div className='ico'>
                    <img src='/images/ico_setting_speaker.png' alt='' />
                  </div>
                  <div className='test'>
                    <p>{t('speaker_check')}</p>
                    <div className='search'>
                      <audio
                        style={{ width: '207px', height: '33px' }}
                        controls
                        controlsList='nodownload'
                        src='https://eggschool.s3.ap-northeast-2.amazonaws.com/web/sound/speaker_test.mp3'>
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                      {/* <button>
                        <PlayCircleFilledIcon
                          fontSize='large'
                          color='secondary'
                        />
                      </button>
                      <button>
                        <PauseCircleFilledIcon
                          fontSize='large'
                          color='disabled'
                        />
                      </button> */}
                    </div>
                  </div>
                  <div className='volumeArea'></div>
                </div>
                <div className='line'></div>
              </div>
              {showSiren && (
                <div className='testDiv'>
                  <div className='tDtop'>
                    <div className='settingSiren'>
                      <img
                        src='/images/siren1.png'
                        alt='issue call'
                        width='30'
                        height='30'
                        color='red'
                      />
                    </div>
                    <div className='test'>
                      <p>
                        수업 진행 중 오류 발생 시 오른쪽 상단 'Help' 버튼을 눌러
                        주세요.
                      </p>
                    </div>
                  </div>
                  {/* <div className='line'></div> */}
                </div>
              )}
            </div>

            <div className='setNoti'>
              <span>
                <button
                  style={{ color: 'white' }}
                  id='close'
                  onClick={handleClose}>
                  {t('confirm')}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <OverWrapper />
    </>
  );
}

const OverWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  overflow: hidden;
  z-index: 999;
`;

export default Settings;
