import React, { useContext, useEffect, useRef, useState } from 'react';
import { OpenVidu, Session } from 'openvidu-browser';
import axios from 'axios';
import { ZOOMUP_API_SERVER, ZOOMUP_WEBSOCKET } from '../config/config';
import Layout from '../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEpisodePage, setCurrentPage } from 'store/book.slice';
import {
  clearScreen,
  playMedia,
  setSpeaker,
  setTimer,
  setZoomIn,
  showStamp,
  startClock,
  stopMedia,
  updateMedia,
} from 'store/videoRoom.slice';
import VideoCallContext from 'contexts/videocall.context';
import Settings from 'components/Settings';
import { sendSessionId } from 'api/classInfo.api';
import { initialUserValue, IUser } from 'models/videocall.model';
import { exitRedirection, sendCommand } from 'utils/libs';
import Seo from './Seo';
import { setCurrentEpisode } from 'store/episode.slice';
import Survey from './Survey';
import { RootState } from '../store/rootReducer';
import { io, Socket } from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { ClassMode } from '../models';
import OverlapUser from './UI/Modal/OverlapUser';

// let users: IUser[] = [];
function VideoRoom() {
  const {
    // setRemoteUsers,
    // remoteUsers,
    startRecording,
    localUser,
    classInfo,
    setLocalUser,
    setShowSetting,
    showSetting,
    session,
    setSession,
    token,
    setToken,
  } = useContext(VideoCallContext);
  const { selectedVideoInput, selectedAudioInput, publisher } = localUser;
  const [remoteUsers, setRemoteUsers] = useState<IUser[]>([]);
  const remoteUsersRef = useRef<IUser[]>([]);
  const tokenRef = useRef('');
  const sessionIdRef = useRef('');
  const userTypeRef = useRef('');
  const socketRef = useRef<Socket>();
  const { t } = useTranslation();
  const { userType, userName, userID } = localUser;
  const { service, uuid } = classInfo;
  const ovRef = useRef<any>(new OpenVidu());
  const [completeSetting, SetCompleteSetting] = useState(false);
  const [length, setLength] = useState(0);
  const [showOverlap, setShowOverlap] = useState(false);
  const [isReconnected, setIsReconnected] = useState(false);

  const { isLevelTest } = classInfo;

  const dispatch = useDispatch();
  const { showSurvey } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    window.addEventListener('beforeunload', onbeforeunload);
    window.addEventListener('unload', onLeave);
    window.addEventListener('resize', onResize);
    joinSession();
    return () => {
      window.removeEventListener('beforeunload', onbeforeunload);
      window.removeEventListener('unload', onLeave);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    remoteUsersRef.current = remoteUsers;
    tokenRef.current = token;
    if (session && session.sessionId) {
      sessionIdRef.current = session.sessionId;
    }
    if (localUser && localUser.userType) {
      userTypeRef.current = localUser.userType;
    }
  }, [token, remoteUsers, session, localUser]);

  const joinSession = () => {
    try {
      const OV = ovRef.current;
      OV.enableProdMode();
      const session = OV.initSession();
      subscribeToStreamCreated(session);
      connectToSession(session);
      setSession(session);

      subscribeToUserChanged(session);
      subscribeToStreamDestroyed(session);
      sendSignalUserChanged({
        isScreenShareActive: false,
      });
    } catch (e) {
      disconnectionError();
    }
  };

  useEffect(() => {
    if (!uuid || !token) return;
    if (socketRef.current) return;
    const socket = io(ZOOMUP_WEBSOCKET ?? '');
    socket.on('connect', () => {
      console.log('socket.connected: ', socket.connected);
      console.log('socket.id: ', socket.id);

      socket.emit('init', {
        uuid,
        token,
        user: {
          userId: localUser.userID,
          userType: localUser.userType,
        },
      });
    });
    socket.on('overlap', () => {
      setShowOverlap(true);
    });
    socketRef.current = socket;
  }, [uuid, token, isLevelTest, localUser]);

  const connectToSession = async (session: Session) => {
    /** get Token  */
    if (!uuid) {
      alert('can not connect video room!!');
      return;
    }
    try {
      // const response = await getToken(uuid, {
      //   userType: localUser.userType,
      //   userId: localUser.userID,
      // });
      const response = await axios.post(`${ZOOMUP_API_SERVER}/api/get-token`, {
        sessionName: uuid,
        user: {
          userType: localUser.userType,
          userId: localUser.userID,
        },
      });
      const token = response.data.token;
      setToken(token);
      await session.connect(token, { userType, userName, userID });
      session.on('reconnecting', () => {
        setTimeout(() => {
          if (!isReconnected) disconnectionError();
          else setIsReconnected(false);
        }, 5000);
      });
      session.on('reconnected', () => {
        setIsReconnected(true);
      });
      session.on('sessionDisconnected', (e) => {
        // @ts-ignore
        if (e?.reason !== 'forceDisconnectByServer') {
          // @ts-ignore
          console.log('reason: ', e?.reason);
          disconnectionError();
        }
      });
    } catch (error) {
      disconnectionError();
    }
    session.on('signal:command', (event: any) => {
      const data = JSON.parse(event.data);
      execCommand(data);
    });
    let classMode: ClassMode;
    switch (service) {
      case 'eggschool':
        classMode = ClassMode.CLASSROOM;
        break;
      case 'mock':
        classMode = ClassMode.MOCK;
        break;
      case 'eggplay':
        classMode = ClassMode.COMON;
        break;
      default:
        classMode = ClassMode.COMON;
    }
    sendSessionId(uuid, session.sessionId, classMode);
    setShowSetting(true);
  };

  const subscribeToStreamCreated = (session: any) => {
    session.on('streamCreated', async (event: any) => {
      const subscribers = remoteUsersRef.current;
      const subscriber = session.subscribe(event.stream, undefined);
      const { userName, userType, userID } = JSON.parse(
        event.stream.connection.data
      );

      checkStartSession(userType);
      const newUser: IUser = {
        userName,
        userType,
        userID,
        connectionId: event.stream.connection.connectionId,
        audioActive: true,
        videoActive: true,
        selectedVideoInput: '',
        selectedAudioInput: '',
        selectedAudioOutput: '',
        screenShareActive: false,
        publisher: subscriber,
        type: 'remote',
      };
      subscribers.push(newUser);
      // users.push(newUser);

      setRemoteUsers(subscribers);
      setLength(subscribers.length);
      if (localUser) {
        sendSignalUserChanged({
          isAudioActive: true,
          isVideoActive: true,
          nickname: localUser.userName,
          isScreenShareActive: false,
        });
      }
    });
  };

  const onChangeSetting = async () => {
    const OV = ovRef.current;

    const constraint = {
      audioSource: selectedAudioInput ? selectedAudioInput : undefined,
      videoSource: selectedVideoInput ? selectedVideoInput : undefined,
      publishAudio: true,
      publishVideo: true,
      resolution: '320x240', // "160x120", // "640x480", "320x240"
      frameRate: 8,
      insertMode: 'APPEND',
    };

    if (completeSetting) {
      const newStream = await OV.getUserMedia({
        audioSource: selectedAudioInput ? selectedAudioInput : undefined,
        videoSource: selectedVideoInput ? selectedVideoInput : undefined,
        publishAudio: true,
        publishVideo: true,
        resolution: '320x240', // "160x120", // "640x480", "320x240"
        frameRate: 8,
        insertMode: 'APPEND',
      });

      const videoTrack = newStream.getVideoTracks()[0];
      const audioTrack = newStream.getAudioTracks()[0];
      publisher.videoReference.muted = true;
      await publisher.replaceTrack(videoTrack);
      await publisher.replaceTrack(audioTrack);
    } else {
      const newPublisher = OV.initPublisher(undefined, constraint);
      if (session.capabilities.publish) {
        session.publish(newPublisher).then(() => {
          if (localUser.userType === 'tutor') {
            sendCommand(session, {
              command: 'changeSpeaker',
              userID: localUser.userID,
            });
          }
        });
      }
      const connectionId = session.connection.connectionId;
      setLocalUser({ ...localUser, publisher: newPublisher, connectionId });
      newPublisher.on('streamPlaying', (e: any) => {
        console.log(
          'start recording in video room',
          localUser.userType,
          classInfo.service,
          classInfo.classMode?.toUpperCase()
        );
        if (
          localUser.userType === 'tutor' &&
          (classInfo.service === 'eggschool' ||
            classInfo.service === 'eggplay') &&
          classInfo.classMode?.toUpperCase() === 'CLASSROOM'
        ) {
          handleStartRecording();
        }
        setLength(-1);
      });
    }
    setShowSetting(false);
    SetCompleteSetting(true);
  };
  const onbeforeunload = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const onResize = () => {};

  const handleLeaveButton = () => {};

  const onLeave = async () => {
    try {
      const token = tokenRef.current;
      const sessionId = sessionIdRef.current;
      const userType = userTypeRef.current;
      const sendData = JSON.stringify({
        sessionName: uuid,
        token,
        sessionId,
        userType,
      });
      navigator.sendBeacon(`${ZOOMUP_API_SERVER}/api/remove-user`, sendData);
      session.disconnect();
      ovRef.current = null;
      setRemoteUsers([]);
      setLocalUser(initialUserValue);
      setSession(null);

      if (window.opener) {
        // window.opener.location.reload();
        window.close();
      } else {
        if (localUser.userType === 'tutor') {
          window.location.href = 'https://tutor.eggschool.net';
        } else {
          window.location.href = 'https://e.eggschool.net';
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sendSignalUserChanged = (data: any) => {
    if (session) {
      const signalOptions = {
        data: JSON.stringify(data),
        type: 'userChanged',
      };
      session.signal(signalOptions);
    }
  };
  const subscribeToStreamDestroyed = (session: any) => {
    // On every Stream destroyed...

    session.on('streamDestroyed', (event: any) => {
      deleteSubscriber(event.stream);
      setTimeout(() => {
        // this.checkSomeoneShareScreen();
      }, 20);
      event.preventDefault();
    });
  };

  const deleteSubscriber = (stream: any) => {
    const remoteUsers = remoteUsersRef.current;
    const userStream = remoteUsers.filter(
      (user) => user.publisher.stream === stream
    )[0];
    let index = remoteUsers.indexOf(userStream, 0);
    if (index > -1) {
      remoteUsers.splice(index, 1);
      setRemoteUsers(remoteUsers);
      setLength(remoteUsers.length);
    }
  };

  const subscribeToUserChanged = (session: any) => {
    // const remoteUsers = remoteUsersRef.current;
    session.on('signal:userChanged', async (event: any) => {
      const remoteUsers = remoteUsersRef.current;
      const newUsers = remoteUsers.map((user) => {
        if (user.connectionId === event.from.connectionId) {
          const data = JSON.parse(event.data);

          let { videoActive, audioActive } = user;
          if (data.videoActive !== undefined) {
            videoActive = data.videoActive;
          }
          if (data.audioActive !== undefined) {
            audioActive = data.audioActive;
          }
          return {
            ...user,
            videoActive,
            audioActive,
          };
        } else {
          return user;
        }
      });
      setRemoteUsers(newUsers);
    });
  };

  const execCommand = (data: any) => {
    if (data.command === 'setPage') {
      dispatch(setCurrentPage(Number(data.index)));
    } else if (data.command === 'changeSpeaker') {
      dispatch(setSpeaker(data.userID));
    } else if (data.command === 'setZoomIn') {
      dispatch(setZoomIn(data.isZoomIn));
    } else if (data.command === 'setTimer') {
      dispatch(setTimer(data.seconds));
    } else if (data.command === 'changeEpisode') {
      dispatch(fetchEpisodePage(data.episodeId));
      dispatch(setCurrentEpisode(Number(data.episodeId)));
      dispatch(setCurrentPage(0));
    } else if (data.command === 'eraseAll') {
      dispatch(clearScreen());
    } else if (data.command === 'showStamp') {
      dispatch(showStamp(data.url));
    } else if (data.command === 'playMedia') {
      dispatch(playMedia(data.url));
    } else if (data.command === 'stopMedia') {
      dispatch(stopMedia());
    } else if (data.command === 'updateMedia') {
      const { url, play, duration, currentTime } = data;
      dispatch(
        updateMedia({
          url,
          play,
          duration,
          currentTime,
        })
      );
      // dispatch(stopMedia());
    }
  };

  const checkStartSession = (remoteUserType: string) => {
    if (remoteUserType === 'student' && localUser.userType === 'tutor') {
      dispatch(startClock());
    }
  };

  const handleStartRecording = () => {
    if (localUser.userType === 'tutor') {
      startRecording(session.sessionId);
    }
  };

  const disconnectionError = () => {
    if (window.confirm(t('disconnected'))) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const handleOk = () => {
    if (isLevelTest === undefined || !localUser?.userType) return;
    exitRedirection(service, isLevelTest, localUser.userType);
  };

  const handleClose = () => {
    setShowOverlap(false);
  };

  return (
    <>
      <div>
        {showSetting && (
          <Settings
            onClose={onChangeSetting}
            showSiren={
              service === 'eggschool' && localUser.userType === 'student'
            }
          />
        )}
        <Layout
          onLeave={handleLeaveButton}
          remoteUsers={remoteUsers}
          length={length}
          service={service}
          classMode={classInfo.classMode?.toUpperCase()}
        />
        <Seo service={service} />
        {showSurvey && <Survey />}
      </div>
      {showOverlap && <OverlapUser onClose={handleClose} onOk={handleOk} />}
    </>
  );
}

export default VideoRoom;
