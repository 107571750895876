import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function BookmarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M4,0C2.3,0,1,1.3,1,3v14c0,1.7,1.3,3,3,3h15V0H4z M13,2v3.4l-1-0.6l-1,0.6V2H13z M3,17V3c0-0.6,0.4-1,1-1h1  v16H4C3.4,18,3,17.6,3,17z M17,18H7V2h2v7l3-1.8L15,9V2h2V18z'></path>
    </SvgIcon>
  );
}
export default BookmarkIcon;
