/** Video Room Status */

export enum IToolMode {
  PEN = 'pen',
  TEXT = 'text',
  ERASER = 'eraser',
  NONE = 'none',
}

export interface IMedia {
  url: string;
  duration: number;
  play: boolean;
  currentTime: number;
}

export interface IVideoRoom {
  isZoomIn: boolean;
  toolMode: IToolMode;
  showBookMark: boolean;
  showTip: boolean;
  speaker: string /** 발표자 , text/pencil의 owner */;
  timer: number;
  clearScreenHolder: number /**  숫자가 증가할 때마다, 드로잉을 초기화 한다. */;
  stamps: {
    stampsHolder: number;
    url: string;
  };
  clock: number /* 숫자가 변화할 떄 카운딩한다. */;
  media: IMedia;
}

export const initialRoomState: IVideoRoom = {
  isZoomIn: false,
  toolMode: IToolMode.NONE,
  showBookMark: false,
  showTip: false,
  speaker: '',
  timer: -1,
  clearScreenHolder: 0,
  stamps: {
    stampsHolder: 0,
    url: '',
  },
  clock: -1,
  media: {
    url: '',
    duration: 0,
    play: false,
    currentTime: 0,
  },
};
