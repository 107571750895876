import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IVideoRoom,
  IToolMode,
  initialRoomState,
} from 'models/videoRoom.model';

const VideoRoomSlicer = createSlice({
  name: 'videoRoom',
  initialState: initialRoomState,
  reducers: {
    setZoomIn: (state: IVideoRoom, action: PayloadAction<boolean>) => {
      state.isZoomIn = action.payload;
    },
    setToolMode: (state: IVideoRoom, action: PayloadAction<IToolMode>) => {
      state.toolMode = action.payload;
    },
    setShowBookMark: (state: IVideoRoom, action: PayloadAction<boolean>) => {
      state.showBookMark = action.payload;
    },
    setShowTip: (state: IVideoRoom, action: PayloadAction<boolean>) => {
      state.showTip = action.payload;
    },
    setSpeaker: (state: IVideoRoom, action: PayloadAction<string>) => {
      state.speaker = action.payload;
    },
    setTimer: (state: IVideoRoom, action: PayloadAction<number>) => {
      state.timer = action.payload;
    },

    clearScreen: (state: IVideoRoom) => {
      state.clearScreenHolder = state.clearScreenHolder + 1;
    },

    showStamp: (state: IVideoRoom, action: PayloadAction<string>) => {
      state.stamps = {
        stampsHolder: state.stamps.stampsHolder + 1,
        url: action.payload,
      };
    },
    startClock: (state: IVideoRoom) => {
      state.clock = state.clock + 1;
    },

    playMedia: (state: IVideoRoom, action: PayloadAction<string>) => {
      state.media = {
        play: true,
        url: action.payload,
        duration: 0,
        currentTime: 0,
      };
    },

    stopMedia: (state: IVideoRoom) => {
      state.media = {
        play: false,
        url: '',
        duration: 0,
        currentTime: 0,
      };
    },

    updateMedia: (
      state: IVideoRoom,
      action: PayloadAction<{
        url: string;
        play: boolean;
        duration: number;
        currentTime: 0;
      }>
    ) => {
      state.media = action.payload;
    },
  },
});

export const {
  setZoomIn,
  setToolMode,
  setShowBookMark,
  setShowTip,
  setSpeaker,
  setTimer,
  clearScreen,
  showStamp,
  startClock,
  playMedia,
  stopMedia,
  updateMedia,
} = VideoRoomSlicer.actions;
export default VideoRoomSlicer.reducer;
