export interface IPen {
  color: string;
  lineWidth: string;
}

export interface IText {
  color: string;
}
export interface IUser {
  userPen: IPen;
  userText: IText;
  showSurvey: boolean;
}

export interface ISummaryUser {
  userType?: 'student' | 'tutor';
  userId: string;
}

export const initialUserState: IUser = {
  userPen: {
    color: 'black',
    lineWidth: '3',
  },
  userText: {
    color: 'black',
  },
  showSurvey: false,
};
