import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import '../../Settings.css';

interface IProps {
  onClose(): void;

  onOk(): void;
}

const OverlapUser: FC<IProps> = ({ onClose, onOk }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='popWrap' style={{ opacity: 1, marginTop: '-344px' }}>
        <div className='guTop'>
          <div className='guHead'>
            <div className='guClosed'>
              <button onClick={onClose}>
                <img src='/images/btn_pop_close.png' alt='Close' />
              </button>
            </div>
          </div>
          <div className='guCont'>
            <div className='innerBox'>
              <div className='tit'>{t('overlapTit')}</div>
              <p style={{ fontSize: 16, color: 'black' }}>{t('overlap')}</p>
            </div>
            <div className='setNoti'>
              <span>
                <button style={{ color: 'white' }} id='close' onClick={onOk}>
                  {t('confirm')}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverlapUser;
