import React, { useContext, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import VideoCallContext from 'contexts/videocall.context';
import './TimerButton.css';
import { sendCommand } from '../../utils/libs';
import IconButton from '@material-ui/core/IconButton';
import ClockIcon from '../Icons/ClockIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { Session, ConnectionEvent } from 'openvidu-browser';

function TimerButton() {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [proceeding, setProceeding] = React.useState<boolean>(false);
  const { session } = useContext(VideoCallContext);
  const { timer } = useSelector((state: RootState) => state.videoRoom);

  useEffect(() => {
    if (timer <= -1) {
      setProceeding(false);
    }
  }, [timer]);

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (proceeding) {
      sendCommand(session, {
        command: 'setTimer',
        seconds: -1,
      });
      setProceeding(false);
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
    setProceeding(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleTimer = (seconds: number) => {
    sendCommand(session, {
      command: 'setTimer',
      seconds: seconds,
    });
    handleClose();
  };
  return (
    <li>
      <IconButton onClick={handleOpen}>
        <ClockIcon
          style={{ fontSize: 40, opacity: 1 }}
          color={open ? 'secondary' : 'primary'}
        />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}>
        <ul id='stampContainer'>
          <li className='stampLi'>
            <button onClick={() => handleTimer(60)}>60</button>
          </li>
          <li className='stampLi'>
            <button onClick={() => handleTimer(20)}>20</button>
          </li>
          <li className='stampLi'>
            <button onClick={() => handleTimer(15)}>15</button>
          </li>
          <li className='stampLi'>
            <button onClick={() => handleTimer(10)}>10</button>
          </li>
          <li className='stampLi'>
            <button onClick={() => handleTimer(5)}>5</button>
          </li>
        </ul>
      </Popover>
    </li>
  );
}

export default TimerButton;
