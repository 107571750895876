export interface IChatMessage {
  message: string;
  userName: string;
  connectionId: any;
}

/** Not use */
export interface IRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export const RectInitialState: IRect = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

export enum ClassMode {
  MOCK = 'mock',
  MOCK_RETRY = 'mock_retry',
  CLASSROOM = 'CLASSROOM',
  COMON = 'comon',
}
