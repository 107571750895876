import React, { useState } from 'react';
import {
  IVideoCall,
  IUser,
  IClassInfo,
  initialUserValue,
  initialClassInfoValue,
} from 'models/videocall.model';
import VideoCallContext from './videocall.context';
import { doStartRecording, doStopRecording } from 'api/rtc.api';

interface IProps {
  children: React.ReactNode;
}

const VideoCallProvider = ({ children }: IProps) => {
  const setServer = (server: number) => {
    setVideoCall((prevState) => {
      return {
        ...prevState,
        server,
      };
    });
  };

  const setToken = (token: string) => {
    setVideoCall((prevState) => {
      return {
        ...prevState,
        token,
      };
    });
  };

  const setLocalUser = (localUser: IUser) => {
    setVideoCall((prevState) => {
      return {
        ...prevState,
        localUser,
      };
    });
  };

  const setSession = (session: any) => {
    setVideoCall((prevState) => {
      return {
        ...prevState,
        session,
      };
    });
  };
  const setClassInfo = (classInfo: IClassInfo) => {
    setVideoCall((prevState) => {
      return {
        ...prevState,
        classInfo,
      };
    });
  };

  const setRemoteUsers = (remoteUsers: IUser[]) => {
    console.log('remoteUsers: inside', remoteUsers);
    setVideoCall((prevState) => {
      return {
        ...prevState,
        remoteUsers,
      };
    });
  };

  const startRecording = async (sessionId: string): Promise<boolean> => {
    try {
      const response = await doStartRecording(sessionId);
      const recordingId = response.data.id;
      setVideoCall((prevState) => {
        return {
          ...prevState,
          recordingId,
        };
      });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  const stopRecording = async (sessionId: string): Promise<boolean> => {
    try {
      if (sessionId) {
        await doStopRecording(sessionId);
        setVideoCall((prevState) => {
          return {
            ...prevState,
            isRecorded: true,
          };
        });
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  const setShowSetting = (showSetting: boolean) => {
    setVideoCall((prevState) => {
      return {
        ...prevState,
        showSetting,
      };
    });
  };

  const initialValue: IVideoCall = {
    server: 1,
    token: '',
    localUser: initialUserValue,
    session: undefined,
    classInfo: initialClassInfoValue,
    // sessionName: '',
    remoteUsers: [],
    showSetting: false,
    recordingId: '',
    isRecorded: false,
    setServer,
    setToken,
    setLocalUser,
    setSession,
    setClassInfo,
    // setSessionName,
    setRemoteUsers,
    startRecording,
    stopRecording,
    setShowSetting,
  };

  const [videoCall, setVideoCall] = useState<IVideoCall>(initialValue);

  return (
    <VideoCallContext.Provider value={videoCall}>
      {children}
    </VideoCallContext.Provider>
  );
};

export default VideoCallProvider;
