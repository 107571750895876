import React, { useEffect, useState } from 'react';

export interface IProps {
  onChange: (lineWidth: string) => void;
}
const LINE_WIDTHS = ['8', '7', '6', '5', '4', '3'];

function LinePicker({ onChange }: IProps) {
  const [hoverId, setHoverId] = useState('2');

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onChange(event.currentTarget.id);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1em',
        borderRadius: '8px',
        width: '200px',
      }}>
      {LINE_WIDTHS.map((line, index) => (
        <div
          key={index}
          style={{
            padding: '2px',
            marginBottom: '10px',
            opacity: line === hoverId ? 1 : 0.6,
            background: line === hoverId ? 'black' : 'azure',
          }}>
          <div
            // onMouseEnter={(e: MouseEvent<HTMLDivElement, MouseEvent>) => {
            //   setHoverId(e.currentTarget.id);
            //   onChange && onChange(e.currentTarget.id);
            // }}
            id={`${line}`}
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
              setHoverId(event.currentTarget.id);
            }}
            onClick={handleClick}
            // onMouseLeave={(e) => setHoverId(undefined)}
            style={{
              width: '200px',
              borderBottom: `${line}px solid #000000`,
            }}
          />
        </div>
      ))}
    </div>
  );
}
export default LinePicker;
