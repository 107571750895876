import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function EraseAllIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M20,7c0-0.8-0.3-1.6-0.9-2.1l-4-4c-1.1-1.1-3.1-1.1-4.2,0l-9.9,9.9C0.3,11.4,0,12.1,0,12.9   c0,0.8,0.3,1.6,0.9,2.1L4.8,19l4.5,0l9.8-9.8C19.7,8.6,20,7.8,20,7z M8.5,17l-2.8,0l-3.4-3.4C2.1,13.4,2,13.2,2,12.9   c0-0.3,0.1-0.5,0.3-0.7l1.8-1.8l5.5,5.5L8.5,17z M17.7,7.7l-6.8,6.8L5.5,9l6.8-6.8c0.4-0.4,1-0.4,1.4,0l4,4C17.9,6.5,18,6.8,18,7   C18,7.3,17.9,7.6,17.7,7.7z'></path>
        <rect x='12' y='18' fill={props.color} width='8' height='2'></rect>
      </g>
    </SvgIcon>
  );
}
export default EraseAllIcon;
