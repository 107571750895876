import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Timer.css';
import { RootState } from 'store/rootReducer';
import { setTimer } from 'store/videoRoom.slice';

export interface IProps {}

function Timer({}: IProps) {
  const refTime = useRef(0);
  const { timer } = useSelector((state: RootState) => state.videoRoom);
  const dispatch = useDispatch();

  useEffect(() => {
    refTime.current = timer;
  }, [timer]);
  useEffect(() => {
    const timeId = setInterval(() => {
      const timer = refTime.current;
      dispatch(setTimer(timer - 1));
    }, 1000);
    return () => {
      clearInterval(timeId);
    };
  }, []);
  return (
    <div id='timer'>
      <span id='seconds'>{timer}</span>
    </div>
  );
}
export default Timer;
