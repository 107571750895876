import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BookViewer from './BookViewer';
import Stamp from './Stamp';
import { RootState } from 'store/rootReducer';

interface IProps {
  height: number;
}

interface IRect {
  width: number;
  height: number;
}
/**
 *  기본 교재 사이즈 1280*905
 */
const screenRatio = 1280 / 905;
let stampHolder = 0;
function Book({ height }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const { pages, currentPage } = useSelector((state: RootState) => state.book);
  const [showStamp, setShowStamp] = useState(false);

  const { stamps } = useSelector((state: RootState) => state.videoRoom);
  const [bookRect, setBookRect] = useState<IRect>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (stamps.stampsHolder > stampHolder) {
      setShowStamp(true);
      setTimeout(() => {
        setShowStamp(false);
      }, 5000);
    }
    stampHolder = stamps.stampsHolder;
  }, [stamps, stamps.stampsHolder]);

  useEffect(() => {
    updateLayout();
    window.addEventListener('load', updateLayout);
    window.addEventListener('resize', updateLayout);
    return () => {
      window.removeEventListener('load', updateLayout);
      window.removeEventListener('resize', updateLayout);
    };
  }, []);

  useEffect(() => {
    if (width / height > screenRatio) {
      /** 가로가 더 길다, 즉 좌우 letter box 생김  */
      const bookRect = {
        width: height * screenRatio,
        height: height,
      };
      setBookRect(bookRect);
    } else {
      /** 세로 더 길다, 즉  상하에  letter box 생김  */
      const bookRect = {
        width: width,
        height: width / screenRatio,
      };
      setBookRect(bookRect);
    }
  }, [height, width]);
  const updateLayout = () => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  };

  return (
    <div
      ref={ref}
      style={{
        // backgroundColor: '#ccc',
        // width: '100%',
        height: height,
        display: 'flex',
        margin: '0 auto',
        borderRadius: '14px',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <BookViewer
        width={bookRect.width}
        height={bookRect.height}
        page={pages[currentPage]}
      />
      {showStamp && <Stamp url={stamps.url} />}
    </div>
  );
}

export default Book;
