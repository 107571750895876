export interface IEpisode {
  episodeId: number;
  stepName: string;
  className: string;
  bookName: string;
}

export interface IEpisodeState {
  episodes: IEpisode[];
  currentEpisode: number;
}

export const initialEpisodeState: IEpisodeState = {
  episodes: [],
  currentEpisode: -1,
};
