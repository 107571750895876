import React, { useContext } from 'react';
import { IEpisode } from '../../models/episode.model';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { setCurrentEpisode } from 'store/episode.slice';
import VideoCallContext from 'contexts/videocall.context';
import { sendCommand } from 'utils/libs';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 200,
      zIndex: 10,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      paddingLeft: 10,
      backgroundColor: 'white',
      color: 'black',
    },

    item: {
      zIndex: 100,
      backgroundColor: 'white',
      color: 'red',
    },
  })
);

export interface IProps {
  episodes: IEpisode[];
}

function Episodes({ episodes }: IProps) {
  const classes = useStyles();
  const { currentEpisode } = useSelector((state: RootState) => state.episode);
  const { session } = useContext(VideoCallContext);
  const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setCurrentEpisode(event.target.value as number));
    sendCommand(session, {
      command: 'changeEpisode',
      episodeId: event.target.value as number,
    });
  };

  return (
    <FormControl className={classes.formControl}>
      {/* <InputLabel id='demo-simple-select-label'> Change Episode</InputLabel> */}
      <NativeSelect
        className={classes.selectEmpty}
        id='demo-simple-select'
        value={currentEpisode}
        onChange={handleChange}>
        {episodes.map((episode) => (
          <option
            value={episode.episodeId}
            className={classes.item}
            key={episode.episodeId}>
            {episode.bookName}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
export default Episodes;
