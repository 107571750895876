import React, { ReactChildren, ReactChild } from "react";
import IconButton from "@material-ui/core/IconButton";

interface IProps {
  // children: JSX.Element[] | JSX.Element;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}
function Button({ children, disabled = false, onClick, ...rest }: IProps) {
  return disabled ? (
    <>{children}</>
  ) : (
    <IconButton onClick={onClick}>{children}</IconButton>
  );
}

export default Button;
