import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { IClassInfo } from 'models/videocall.model';
import { getClassInfo, pingEggComon } from 'api/classInfo.api';
import VideoCallContext from 'contexts/videocall.context';
import VideoRoom from './VideoRoom';
import Loader from './UI/Loader';
import { fetchEpisodePage } from 'store/book.slice';
import { setCurrentEpisode, setEpisodes } from 'store/episode.slice';
import { sendInOutLog, pingStudy } from '../api/classInfo.api';

interface ParamTypes {
  service: string;
  mode: string;
  uuid: string;
}

function GateWay() {
  const location = useLocation();
  const { service, uuid, mode } = useParams<ParamTypes>();
  const parsed = queryString.parse(location.search);
  const token = parsed.token ? parsed.token.toString() : '';
  const episodeId = parsed.episodeId ? parsed.episodeId.toString() : '';

  const { setServer, localUser, setLocalUser, setClassInfo, classInfo } =
    useContext(VideoCallContext);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeId: NodeJS.Timeout;
    const init = async () => {
      try {
        const data = episodeId
          ? await getClassInfo(service, uuid, mode, token, parseInt(episodeId))
          : await getClassInfo(service, uuid, mode, token);
        let {
          userName,
          userType,
          userID,
          classInfo,
          server,
          episodes,
          scheduleId,
          startDateTime,
        } = data;

        /** 추가 확인 바람  */
        if (!userID) {
          userID = userType;
          userName = userType;
        }

        const isLevelTest = classInfo.episodeId === 402;

        const newClassInfo: IClassInfo = {
          uuid,
          service,
          classMode: classInfo.classMode.toUpperCase(),
          capacity: classInfo.capacity,
          episodeId: classInfo.episodeId,
          className: classInfo.className,
          bookName: classInfo.bookName,
          stepName: classInfo.stepName ? classInfo.stepName : '',
          scheduleId: scheduleId ? scheduleId : 0,
          startDateTime,
          isLevelTest,
        };
        const newUser = { ...localUser, userName, userType, userID };
        setLocalUser(newUser);
        setClassInfo(newClassInfo);
        setServer(server);

        dispatch(fetchEpisodePage(classInfo.episodeId));
        if (episodes) {
          dispatch(setEpisodes(episodes));
          dispatch(setCurrentEpisode(classInfo.episodeId));
        }

        if (service === 'eggschool' && classInfo.classMode === 'CLASSROOM') {
          const payload = { scheduleId, type: 'in' };
          sendInOutLog(payload);
          timeId = setInterval(() => {
            const payload = { scheduleId };
            pingStudy(payload);
          }, 1000 * 3);
        }
        if (service === 'eggplay') {
          timeId = setInterval(() => {
            pingEggComon({ uuid, token });
          }, 1000 * 60 * 3);
        }
      } catch (error) {
        console.log(error);
        alert('Can not get class information!!!');
      }
    };

    init();
    return () => {
      timeId && clearInterval(timeId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!classInfo.uuid) return <Loader />;
  return <VideoRoom />;
}

export default GateWay;
