import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function ClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M19,10c0-0.8-0.1-1.6-0.3-2.3c1.8-1.8,1.8-4.6,0-6.4c-1.8-1.8-4.6-1.8-6.4,0C11.6,1.1,10.8,1,10,1   C9.2,1,8.4,1.1,7.7,1.3c-1.8-1.8-4.6-1.7-6.4,0c-1.8,1.8-1.8,4.6,0,6.4C1.1,8.4,1,9.2,1,10c0,3.1,1.6,5.9,4.1,7.5l-0.8,0.8   c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.8,0.5,1.4,0L7,18.5c1,0.3,2,0.5,3,0.5c1.3,0,2.5-0.3,3.6-0.7l1.4,1.4c0.6,0.5,1.2,0.2,1.4,0   c0.4-0.4,0.4-1,0-1.4l-1.1-1.1C17.6,15.6,19,13,19,10z M17.3,2.7c0.5,0.5,1,1.6,0.5,2.8C17,4.1,15.9,3,14.5,2.2   C15.4,1.8,16.5,2,17.3,2.7z M2.7,2.7C3.5,2,4.6,1.8,5.5,2.2C4.1,3,3,4.1,2.2,5.5C1.8,4.6,2,3.5,2.7,2.7z M10,17c-3.9,0-7-3.1-7-7   c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7C17,13.9,13.9,17,10,17z'></path>
        <path d='M10,5C9.4,5,9,5.4,9,6v3H8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C11,5.4,10.6,5,10,5z'></path>
      </g>
    </SvgIcon>
  );
}
export default ClockIcon;
